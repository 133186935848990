import React, { useEffect, useState, useRef } from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import { Accordion, Card, Button, Modal, Container, Row, Col, ProgressBar, Form, ListGroup, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Slider from '@mui/material/Slider';
import '@mui/material/styles';

const firebaseConfig = {
    // Your Firebase config
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const NetworkGraphs = () => {
    const [networkData, setNetworkData] = useState({});
    const [selectedTimestamp, setSelectedTimestamp] = useState('');
    const [timestamps, setTimestamps] = useState([]);
    const [autoplay, setAutoplay] = useState(false);
    const autoplayRef = useRef(null);

    useEffect(() => {
        const dbRef = firebase.database().ref();
        dbRef.on('value', (snapshot) => {
            const data = snapshot.val().networks;
            setNetworkData(data);

            let allTimestamps = Object.values(data).flatMap(network => Object.keys(network)).sort();
            allTimestamps = [...new Set(allTimestamps)]; // Remove duplicates
            setTimestamps(allTimestamps);
            if (allTimestamps.length > 0) {
                setSelectedTimestamp(allTimestamps[0]);
            }
        });
        return () => dbRef.off();
    }, []);

    // Add a dependency array to the second useEffect to ensure it reruns when necessary
    useEffect(() => {
        if (autoplay && timestamps.length > 0) {
            autoplayRef.current = setInterval(() => {
                setSelectedTimestamp((prevTimestamp) => {
                    const currentIndex = timestamps.indexOf(prevTimestamp);
                    const nextIndex = (currentIndex + 1) % timestamps.length;
                    return timestamps[nextIndex];
                });
            }, 3000); // Change timestamp every 5 seconds
        } else {
            // Cleanup on autoplay stop or when timestamps change
            clearInterval(autoplayRef.current);
        }
        // Adding cleanup function to clear interval when component unmounts or updates
        return () => clearInterval(autoplayRef.current);
    }, [autoplay, timestamps]); // Added timestamps to dependency array to ensure effect reruns when timestamps change

    const handleChange = (event, newValue) => {
        setSelectedTimestamp(timestamps[newValue]);
    };

    const handleAutoplayToggle = () => {
        setAutoplay(!autoplay);
    };

    const getLatestDataForTimestamp = (network, timestamp) => {
        const networkTimestamps = Object.keys(network).sort();
        const latestTimestamp = networkTimestamps.reverse().find(t => t <= timestamp) || networkTimestamps[0];
        return network[latestTimestamp];
    };

    const getNetworksWithSelectedTimestamp = () => {
        return Object.keys(networkData).map(key => ({
            data: getLatestDataForTimestamp(networkData[key], selectedTimestamp),
            id: key
        })).filter(network => network.data);
    };

    const osTypes = ['Android', 'iOS or iPadOS', 'macOS', 'Windows'];
    const osVariants = ['info', 'warning', 'danger', 'success'];

    const Legend = () => (
        <ProgressBar className="mb-3">
            {osTypes.map((os, index) => (
                <ProgressBar
                    key={os}
                    striped
                    variant={osVariants[index]}
                    now={25}
                    label={os}
                />
            ))}
        </ProgressBar>
    );

    const networks = getNetworksWithSelectedTimestamp();

    const getOSProgressBars = (network) => {
        const devices = Array.from(new Set([...(network.data.Switches || []), ...(network.data['Access Points'] || [])]));
        return devices.map((device, deviceIndex) => {
            const deviceData = network.data.Clients ? network.data.Clients[device] : undefined;
            if (!deviceData) return null;

            const totalClients = deviceData.Total;
            const typesAndOS = deviceData['Types and OS'];

            // Mapping device types to variants for progress bars
            const deviceTypeVariants = {
                'Android': 'primary', // Custom
                'iOS or iPadOS': 'warning', // Custom
                'macOS': 'danger', // Custom
                'Windows': 'success', // Custom
                'Computer': 'info',
                'IoT': 'warning',
                'Mobile': 'success',
                'Other': 'secondary', // Added 'secondary' for 'Other' types
                'Tablet': 'danger',
                'Wearable': 'primary' // Added 'primary' for 'Wearable'
                
            };

            return (
                <div key={deviceIndex}>
                    <h6>{device} - {totalClients} Clients</h6>
                    <ProgressBar animated>
                        {Object.entries(typesAndOS || {}).map(([type, count], index) => {
                            const percentage = totalClients > 0 ? (count / totalClients) * 100 : 0;
                            const variant = deviceTypeVariants[type] || 'info'; // Default to 'info' if type not found
                            return (
                                <ProgressBar
                                    key={type}
                                    striped
                                    variant={variant}
                                    now={percentage}
                                    label={`${count} ${type}`}
                                />
                            );
                        })}
                    </ProgressBar>
                </div>
            );
        });
    };



    return (
        <Container fluid>
            <Legend />
            <Form.Check
                type="switch"
                id="autoplay-switch"
                label="Autoplay"
                checked={autoplay}
                onChange={handleAutoplayToggle}
                className="mb-2"
            />
            <Slider
                min={0}
                max={timestamps.length - 1}
                value={timestamps.indexOf(selectedTimestamp)}
                onChange={handleChange}
                valueLabelDisplay="auto"
                valueLabelFormat={(index) => timestamps[index]}
                aria-labelledby="timestamp-slider"
                marks={timestamps.map((timestamp, index) => ({ value: index, label: timestamp }))}
                disabled={autoplay}
                style={{ opacity: autoplay ? 0.5 : 1 }}
            />
            <div className="container-fluid mt-3">
                <div className="row">
                    {networks.map((network) => (
                        <div key={network.id} className="col-12 col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">{network.data['Network Name']}</h5>
                                    {getOSProgressBars(network)}
                                    <ListGroup variant="flush" className="mt-3">
                                        {network.data['WAN1 IP'] && (
                                            <ListGroup.Item>WAN1: {network.data['WAN1 IP']}</ListGroup.Item>
                                        )}
                                        {network.data['WAN2 IP'] && (
                                            <ListGroup.Item>WAN2: {network.data['WAN2 IP']}</ListGroup.Item>
                                        )}
                                    </ListGroup>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Container>
    );
};



export default NetworkGraphs;