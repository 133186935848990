

import React from 'react'
import { Container, Row, Col, Card, Button, Form, Jumbotron } from 'react-bootstrap'
//import GoogleButton from 'react-google-button'
import { useHistory } from 'react-router-dom'
import firebase from "firebase/app"
import MSLogin from './MicrosoftLogin';

import app from "../firebase"
import { useFlashUpdate } from "../contexts/FlashContext"

export default function GoogleLogin() {
    const flash = useFlashUpdate()
    const history = useHistory()

    async function handleLogin(event) {
        event.preventDefault()

        var provider = new firebase.auth.GoogleAuthProvider()
        provider.addScope("https://www.googleapis.com/auth/userinfo.email") // see: https://developers.google.com/identity/protocols/oauth2/scopes

        const result = await app.auth().signInWithPopup(provider)
        //const result = await app.auth().signInWithRedirect(provider)

        var user = result.user
        var providerId = result.additionalUserInfo.providerId
        var profile = result.additionalUserInfo.profile
        var token = result.credential.accessToken
        console.log("USER:", user) // user.uid, user.displayName, user.email, user.emailVerified, user.phoneNumber, user.photoURL, user.refreshToken
        console.log("PROVIDER:", providerId)
        console.log("USER PROFILE:", profile)
        console.log("ACCESS TOKEN", token)
        // TODO: store this user info in the database, perhaps firestore

        console.log("LOGIN SUCCESS")
        flash({message:"Login success. Welcome back!", variant: "success"})
        history.push("/profile")
    }

    return (
        <Container>
            <Row xs={12} md={12} lg={12} className="mt-5 pt-3 text-center">
                <Col>
                    <h2>Hello, Longfellow Real Estate Partners!<i class="bi bi-award-fill text-light"></i></h2>
                    <p style={{ display: "none" }}>
                        This is a simple hero unit, a simple jumbotron-style component for calling
                        extra attention to featured content or information.
                    </p>
                    <p className="lead text-secondary">Welcome, to <b>P</b>roperties, <b>B</b>uildings, and <b>R</b>ooms...</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Container className="d-flex align-items-center justify-content-center">
                        <div className="w-100" style={{ maxWidth: "400px", marginTop: "50px" }}>
                            <Card>
                                <Card.Body>
                                    <h2 className="text-center mb-4">Have a Google Account?</h2>

                                    <Form onSubmit={handleLogin}>
                                        <Button className="w-100 shadow-sm" type="submit" variant="success">
                                            Log In w/ Google
                                        </Button>
                                    </Form>

                                    {/* <GoogleButton onClick={handleLogin} label="Log In w/ Google" /> */}



                                </Card.Body>
                            </Card>
                        </div>
                    </Container>
                </Col>
                <Col>
                    <MSLogin />
                </Col>
            </Row>
            <Row xs={12} md={12} lg={12} className="mt-5 pt-3 text-center">
                <Col md="2">&nbsp;</Col>
                <Col md="8" className="text-center">
                    <p className="lead text-secondary"><h5>Using either Google or Microsoft to login does not collect any information from your account. Your account email and domain will be used to determine your level of access, if any.</h5></p>
                    <p className="text-warning"><h6>Please direct all questions <a href="mailto:sjackson@lfrep.com">here</a>.</h6></p>
                </Col>
                <Col md="2">&nbsp;</Col>
            </Row>
        </Container>
    )
}
