import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Container, Row, Col, Form, Button, Accordion, Card, InputGroup, FormControl, FormCheck, FormGroup } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import firebase from 'firebase/app';
import 'firebase/database';

const firebaseConfig = {
    apiKey: "YOUR_API_KEY",
    authDomain: "YOUR_AUTH_DOMAIN",
    databaseURL: "YOUR_DATABASE_URL",
    projectId: "YOUR_PROJECT_ID",
    storageBucket: "YOUR_STORAGE_BUCKET",
    messagingSenderId: "YOUR_MESSAGING_SENDER_ID",
    appId: "YOUR_APP_ID"
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
} else {
    firebase.app();
}

const AVRooms = () => {
    const [avrooms, setAVRooms] = useState([]);
    const [monitors, setMonitors] = useState({});
    const [newAVRoom, setNewAVRoom] = useState({
        name: '',
        building: '',
        calendar: '',
        use: 'corp',
        maxOccupancy: '',
        type: '',
        sharing: '',
        video: [],
        tvs: { count: '', size: '', brand: '', network: '' }
    });

    useEffect(() => {
        const avroomsRef = firebase.database().ref('avrooms');
        avroomsRef.on('value', (snapshot) => {
            const data = snapshot.val();
            if (data) {
                setAVRooms(Object.values(data));
            } else {
                setAVRooms([]);
            }
        });

        const monitorsRef = firebase.database().ref('/kuma/monitor_status').orderByKey();
        monitorsRef.on('value', (snapshot) => {
            const monitorsData = {};
            snapshot.forEach(childSnapshot => {
                const key = childSnapshot.key;
                if (!key.startsWith("California") && !key.startsWith("East")) {
                    monitorsData[key] = key; // Filter out monitors starting with "California" or "East"
                }
            });
            setMonitors(monitorsData);
        });
    }, []);

    const saveAVRoomToFirebase = (avroom) => {
        const avroomsRef = firebase.database().ref('avrooms');
        const newAVRoomRef = avroomsRef.push();
        avroom.id = newAVRoomRef.key;
        newAVRoomRef.set(avroom);
    };

    const handleAddAVRoom = (event) => {
        event.preventDefault();
        const newAVRoomObject = {
            id: uuidv4(),
            ...newAVRoom,
            createdAt: new Date().toLocaleString(),
        };
        saveAVRoomToFirebase(newAVRoomObject);
        setNewAVRoom({
            name: '',
            building: '',
            calendar: '',
            use: 'corp',
            maxOccupancy: '',
            type: '',
            sharing: '',
            video: [],
            tvs: { count: '', size: '', brand: '', network: '' }
        });
    };

    const handleUpdateAVRoom = (id, key, value) => {
        setAVRooms(avrooms.map(avroom => (avroom.id === id ? { ...avroom, [key]: value } : avroom)));
        const avroomRef = firebase.database().ref('avrooms').child(id);
        avroomRef.update({ [key]: value });
    };

    const handleDeleteAVRoom = (id) => {
        setAVRooms(avrooms.filter(avroom => avroom.id !== id));
        const avroomRef = firebase.database().ref('avrooms').child(id);
        avroomRef.remove();
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;
        const { source, destination } = result;

        const updatedAVRooms = Array.from(avrooms);
        const [movedAVRoom] = updatedAVRooms.splice(source.index, 1);
        updatedAVRooms.splice(destination.index, 0, movedAVRoom);

        setAVRooms(updatedAVRooms);
        const avroomRef = firebase.database().ref('avrooms').child(result.draggableId);
        avroomRef.update({ use: destination.droppableId });
    };

    const avroomForm = (
        <Form onSubmit={handleAddAVRoom} className="py-3 my-3 px-3 bg-dark text-light rounded shadow-sm">
            <Row className="mb-2">
                <Col md={3}>
                    <h5>Room Name / Area</h5>
                    <FormControl
                        type="text"
                        placeholder="Name"
                        value={newAVRoom.name}
                        onChange={(e) => setNewAVRoom({ ...newAVRoom, name: e.target.value })}
                    />
                </Col>
                <Col md={3}>
                    <h5>Property / Building</h5>
                    <FormControl
                        as="select"
                        value={newAVRoom.building}
                        onChange={(e) => setNewAVRoom({ ...newAVRoom, building: e.target.value })}
                    >
                        <option value="">Select Building</option>
                        {Object.values(monitors).sort().map((building, index) => (
                            <option key={index} value={building}>{building}</option>
                        ))}
                    </FormControl>
                </Col>
                <Col md={3}>
                    <h5>Microsoft Calendar Link</h5>
                    <FormControl
                        type="text"
                        placeholder="Calendar Link"
                        value={newAVRoom.calendar}
                        onChange={(e) => setNewAVRoom({ ...newAVRoom, calendar: e.target.value })}
                    />
                </Col>
                <Col md={1}>
                    <h5>Occupancy</h5>
                    <FormControl
                        type="number"
                        placeholder="Max Occupancy"
                        value={newAVRoom.maxOccupancy}
                        onChange={(e) => setNewAVRoom({ ...newAVRoom, maxOccupancy: e.target.value })}
                    />
                </Col>
                <Col md={2}>
                    <h5>Room Type / Media</h5>
                    <FormControl
                        as="select"
                        value={newAVRoom.type}
                        onChange={(e) => setNewAVRoom({ ...newAVRoom, type: e.target.value })}
                    >
                        <option value="">Select</option>
                        <option value="22miles">22 Miles</option>
                        <option value="AirTame">Airtame</option>
                        <option value="ClickShare">Clickshare</option>
                        <option value="Teams">Teams</option>
                        <option value="Direct HDMI">Direct HDMI</option>
                    </FormControl>
                </Col>
            </Row>
            <Row>
                <Col md={2}>
                    <h5>Sharing</h5>
                    <FormControl
                        as="select"
                        value={newAVRoom.sharing}
                        onChange={(e) => setNewAVRoom({ ...newAVRoom, sharing: e.target.value })}
                    >
                        <option value="">Select Sharing</option>
                        <option value="cx-10g2">CX-10 Gen2</option>
                        <option value="cx-30">CX-30</option>
                        <option value="cx-30g2">CX-30 Gen2</option>
                        <option value="cx-50">CX-50</option>
                        <option value="cx-50g2">CX-50 Gen2</option>
                        <option value="airtame">Airtame</option>
                        <option value="hdmi / usb">HDMI / USB</option>
                        <option value="none">None</option>
                    </FormControl>
                </Col>
                <Col md={1}>
                    <h5>TVs</h5>
                    <FormControl
                        type="number"
                        placeholder="TV Count"
                        value={newAVRoom.maxTVs}
                        onChange={(e) => setNewAVRoom({ ...newAVRoom, maxTVs: e.target.value })}
                    />
                </Col>
                <Col md={5}>
                    <h5 className="mb-0 pb-0">Video / Camera</h5><br />
                    <FormGroup>
                        <Row className="mt-0 pt-0">
                            <Col md={3}>
                                <FormCheck
                                    type="checkbox"
                                    label="Logi Rally Bar"
                                    checked={newAVRoom.video.includes("rally bar")}
                                    onChange={(e) => {
                                        const updatedVideo = e.target.checked
                                            ? [...newAVRoom.video, "rally bar"]
                                            : newAVRoom.video.filter(v => v !== "rally bar");
                                        setNewAVRoom({ ...newAVRoom, video: updatedVideo });
                                    }}
                                />
                            </Col>
                            <Col md={3}>
                                <FormCheck
                                    type="checkbox"
                                    label="Camera (Guests)"
                                    checked={newAVRoom.video.includes("ptz camera (guests)")}
                                    onChange={(e) => {
                                        const updatedVideo = e.target.checked
                                            ? [...newAVRoom.video, "ptz camera (guests)"]
                                            : newAVRoom.video.filter(v => v !== "ptz camera (guests)");
                                        setNewAVRoom({ ...newAVRoom, video: updatedVideo });
                                    }}
                                />
                            </Col>
                            <Col md={3}>
                                <FormCheck
                                    type="checkbox"
                                    label="Camera (Host)"
                                    checked={newAVRoom.video.includes("ptz camera (host)")}
                                    onChange={(e) => {
                                        const updatedVideo = e.target.checked
                                            ? [...newAVRoom.video, "ptz camera (host)"]
                                            : newAVRoom.video.filter(v => v !== "ptz camera (host)");
                                        setNewAVRoom({ ...newAVRoom, video: updatedVideo });
                                    }}
                                />
                            </Col>
                            <Col md={3}>
                                <FormCheck
                                    type="checkbox"
                                    label="None"
                                    checked={newAVRoom.video.includes("none")}
                                    onChange={(e) => {
                                        const updatedVideo = e.target.checked
                                            ? [...newAVRoom.video, "none"]
                                            : newAVRoom.video.filter(v => v !== "none");
                                        setNewAVRoom({ ...newAVRoom, video: updatedVideo });
                                    }}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                </Col>
                <Col md={2} className="mt-3 pt-4 text-right">
                    <Button type="submit">Add AV Room</Button>
                </Col>
            </Row>
            <Row className="mt-3 d-none">
                <Col className="text-center">
                    <Button type="submit">Add AV Room</Button>
                </Col>
            </Row>
        </Form>
    );

    const avroomList = (use) => (
        <Droppable droppableId={use}>
            {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                    <Accordion>
                        {avrooms.filter(avroom => avroom.use === use).map((avroom, index) => (
                            <Draggable key={avroom.id} draggableId={avroom.id} index={index} >
                                {(provided) => (
                                    <Card ref={provided.innerRef} {...provided.draggableProps}>
                                        <div {...provided.dragHandleProps} className="drag-handle">
                                            <span className="mr-2 cursor-pointer">
                                                <span style={{ paddingLeft: "0.5em" }}>☰
                                                    <small className="text-secondary float-right pr-2 pt-1">
                                                        click to view more info
                                                    </small>
                                                </span>
                                                <small style={{ float: "right", paddingRight: "0.5em" }} className="d-none">
                                                    Created at: {avroom.createdAt}
                                                </small>
                                            </span>
                                        </div>
                                        <Accordion.Toggle as={Card.Header} eventKey={String(avroom.id)} className="text-light">
                                            {avroom.name}&nbsp;@&nbsp;{avroom.building}
                                            <div className="float-right d-none">
                                                <Button variant="link" size="sm" onClick={() => handleDeleteAVRoom(avroom.id)}>
                                                    Delete
                                                </Button>
                                            </div>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey={String(avroom.id)}>
                                            <Card.Body>
                                                <InputGroup>
                                                    <Container>
                                                        <Row className="py-2">
                                                            <Col md={6}>
                                                                <FormControl
                                                                    type="text"
                                                                    value={avroom.name}
                                                                    onChange={(e) => handleUpdateAVRoom(avroom.id, 'name', e.target.value)}
                                                                    placeholder="Name"
                                                                />
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormControl
                                                                    as="select"
                                                                    value={avroom.building}
                                                                    onChange={(e) => handleUpdateAVRoom(avroom.id, 'building', e.target.value)}
                                                                >
                                                                    <option value="">Select Building</option>
                                                                    {Object.values(monitors).sort().map((building, index) => (
                                                                        <option key={index} value={building}>{building}</option>
                                                                    ))}
                                                                </FormControl>
                                                            </Col>
                                                        </Row>
                                                        <Row className="py-2">
                                                            <Col md={6}>
                                                                <FormControl
                                                                    type="text"
                                                                    value={avroom.calendar}
                                                                    onChange={(e) => handleUpdateAVRoom(avroom.id, 'calendar', e.target.value)}
                                                                    placeholder="Calendar Link"
                                                                />
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormControl
                                                                    type="number"
                                                                    value={avroom.maxOccupancy}
                                                                    onChange={(e) => handleUpdateAVRoom(avroom.id, 'maxOccupancy', e.target.value)}
                                                                    placeholder="Max Occupancy"
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="py-2">
                                                            <Col md={3}>
                                                                <FormControl
                                                                    as="select"
                                                                    value={avroom.type}
                                                                    onChange={(e) => handleUpdateAVRoom(avroom.id, 'type', e.target.value)}
                                                                >
                                                                    <option value="">Select</option>
                                                                    <option value="22miles">22 Miles</option>
                                                                    <option value="AirTame">Airtame</option>
                                                                    <option value="ClickShare">Clickshare</option>
                                                                    <option value="Teams">Teams</option>
                                                                    <option value="Direct HDMI">Direct HDMI</option>
                                                                </FormControl>
                                                            </Col>
                                                            <Col md={3}>
                                                                <FormControl
                                                                    as="select"
                                                                    value={avroom.sharing}
                                                                    onChange={(e) => handleUpdateAVRoom(avroom.id, 'sharing', e.target.value)}
                                                                >
                                                                    <option value="">Select Sharing</option>
                                                                    <option value="cx-10g2">CX-10 Gen2</option>
                                                                    <option value="cx-30">CX-30</option>
                                                                    <option value="cx-30g2">CX-30 Gen2</option>
                                                                    <option value="cx-50">CX-50</option>
                                                                    <option value="cx-50g2">CX-50 Gen2</option>
                                                                    <option value="airtame">Airtame</option>
                                                                    <option value="hdmi / usb">HDMI / USB</option>
                                                                    <option value="none">None</option>
                                                                </FormControl>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Row className="">
                                                                        <Col md={6}>
                                                                            <FormCheck
                                                                                type="checkbox"
                                                                                label="Camera (Guests)"
                                                                                checked={avroom.video.includes("ptz camera (guests)")}
                                                                                onChange={(e) => {
                                                                                    const updatedVideo = e.target.checked
                                                                                        ? [...avroom.video, "ptz camera (guests)"]
                                                                                        : avroom.video.filter(v => v !== "ptz camera (guests)");
                                                                                    handleUpdateAVRoom(avroom.id, 'video', updatedVideo);
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <FormCheck
                                                                                type="checkbox"
                                                                                label="Camera (Host)"
                                                                                checked={avroom.video.includes("ptz camera (host)")}
                                                                                onChange={(e) => {
                                                                                    const updatedVideo = e.target.checked
                                                                                        ? [...avroom.video, "ptz camera (host)"]
                                                                                        : avroom.video.filter(v => v !== "ptz camera (host)");
                                                                                    handleUpdateAVRoom(avroom.id, 'video', updatedVideo);
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <FormCheck
                                                                                type="checkbox"
                                                                                label="Rally Bar"
                                                                                checked={avroom.video.includes("rally bar")}
                                                                                onChange={(e) => {
                                                                                    const updatedVideo = e.target.checked
                                                                                        ? [...avroom.video, "rally bar"]
                                                                                        : avroom.video.filter(v => v !== "rally bar");
                                                                                    handleUpdateAVRoom(avroom.id, 'video', updatedVideo);
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <FormCheck
                                                                                type="checkbox"
                                                                                label="None"
                                                                                checked={avroom.video.includes("none")}
                                                                                onChange={(e) => {
                                                                                    const updatedVideo = e.target.checked
                                                                                        ? [...avroom.video, "none"]
                                                                                        : avroom.video.filter(v => v !== "none");
                                                                                    handleUpdateAVRoom(avroom.id, 'video', updatedVideo);
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                                </InputGroup>
                                                <small>Changes are saved automatically</small>
                                                <div className="float-right d-none">
                                                    <Button variant="danger" size="sm" onClick={() => handleDeleteAVRoom(avroom.id)}>
                                                        Delete
                                                    </Button>
                                                </div>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </Accordion>
                </div>
            )}
        </Droppable>
    );

    return (
        <Container fluid>
            <Row>
                <Col>
                    <h4>AV Room Management</h4>
                    {avroomForm}
                </Col>
            </Row>
            <DragDropContext onDragEnd={onDragEnd}>
                <Row className="mb-3">
                    <Col md={6}>
                        <Card>
                            <Card.Header className="text-light text-uppercase">Corporate Office</Card.Header>
                            <Card.Body>{avroomList('Corp')}</Card.Body>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card>
                            <Card.Header className="text-light text-uppercase">Elevate / Tenant</Card.Header>
                            <Card.Body>{avroomList('Elevate')}</Card.Body>
                        </Card>
                    </Col>
                </Row>
            </DragDropContext>
        </Container>
    );
};

export default AVRooms;
