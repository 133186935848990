import React, { useState, useEffect } from 'react';
import { Table, Container } from 'react-bootstrap';
import firebase from 'firebase/app';
import 'firebase/database';

const SiteRooms = ({ monitor }) => {
    const [rooms, setRooms] = useState([]);

    useEffect(() => {
        const avroomsRef = firebase.database().ref('avrooms');
        avroomsRef.on('value', (snapshot) => {
            const data = snapshot.val();
            if (data) {
                const roomsArray = Object.values(data);
                const filteredRooms = roomsArray.filter(room => room.building === monitor);
                setRooms(filteredRooms.sort((a, b) => a.name.localeCompare(b.name)));
            } else {
                setRooms([]);
            }
        });

        return () => avroomsRef.off();
    }, [monitor]);

    const groupedRooms = rooms.reduce((acc, room) => {
        const type = room.type || 'Unassigned';
        if (!acc[type]) acc[type] = [];
        acc[type].push(room);
        return acc;
    }, {});

    return (
        <Container fluid>
            {Object.entries(groupedRooms).map(([type, typeRooms]) => (
                <div key={type} className="my-3">
                    <h5>{type}</h5>
                    <Table responsive bordered hover>
                        <thead>
                            <tr>
                                <th>Room Name</th>
                                <th className="d-none">Building</th>
                                <th>Calendar</th>
                                <th>Max Occ</th>
                                <th>Sharing</th>
                                <th>Video</th>
                                <th>Purpose</th>
                            </tr>
                        </thead>
                        <tbody>
                            {typeRooms.map(room => (
                                <tr key={room.id}>
                                    <td>{room.name}</td>
                                    <td className="d-none">{room.building}</td>
                                    <td>{room.calendar}</td>
                                    <td>{room.maxOccupancy}</td>
                                    <td>{room.sharing}</td>
                                    <td>{room.video.join(', ')}</td>
                                    <td>{room.use}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            ))}
        </Container>
    );
};

export default SiteRooms;
