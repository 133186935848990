import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Button, Spinner, Form, Badge, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

const NmapScan = () => {
    const [ipAddress, setIpAddress] = useState('');
    const [scanResult, setScanResult] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [links, setLinks] = useState([]);
    const [isValid, setIsValid] = useState(true);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const ip = params.get('ip');
        if (ip) {
            setIpAddress(ip);
        }
    }, []);

    const handleScan = async () => {
        setLoading(true);
        try {
            const response = await axios.post('https://nmap.surveyresults.zip/scan', { ipv4: ipAddress });
            setScanResult(response.data.result);
            setLinks(extractLinks(response.data.result));
            setError(null);
        } catch (err) {
            setError(err.response ? err.response.data : 'Error connecting to the API');
            setScanResult(null);
            setLinks([]);
        } finally {
            setLoading(false);
        }
    };

    const extractLinks = (scanResult) => {
        const lines = scanResult.split('\n');
        const openPorts = lines.filter(line => line.includes('open')).map(line => line.split('/')[0]);
        const httpPorts = openPorts.filter(port => ['80', '8080', '8000'].includes(port));
        const httpsPorts = openPorts.filter(port => ['443', '8443'].includes(port));

        return [
            ...httpPorts.map(port => ({ port, secure: false })),
            ...httpsPorts.map(port => ({ port, secure: true }))
        ];
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (isValid) {
            handleScan();
        }
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        const regex = /^[0-9.]*$/;
        if (regex.test(value)) {
            setIpAddress(value);
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    };

    return (
        <Container>
            <Row className="justify-content-md-center">
                <Col md="4">
                    <h1 className="text-center">nmap scanner</h1>
                    <Form onSubmit={handleFormSubmit}>
                        <Form.Group className="mb-3 text-center shadow">
                            <Form.Label>Single IPv4 Addresses only. No CIDR blocks or IP ranges.</Form.Label>
                            <Form.Control
                                type="text"
                                value={ipAddress}
                                onChange={handleInputChange}
                                className="text-center"
                                placeholder="192.168.1.1 - not 192.168.1.0/24"
                            />
                            {!isValid && (
                                <Badge bg="danger" className="mt-2 text-danger">Invalid input! Only numbers and dots are allowed.<br /></Badge>
                            )}
                        </Form.Group>
                        <div className="d-grid gap-2">
                            <Button
                                variant="primary"
                                className="button-full shadow"
                                size="lg"
                                type="submit"
                                disabled={loading || !isValid}
                            >
                                {loading ? (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        <span className="visually-hidden">Loading...</span>
                                    </>
                                ) : (
                                    'Scan'
                                )}
                            </Button>
                            <p className="text-secondary text-center">results will be shown below</p>
                        </div>
                    </Form>
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                <Col xs={12} md={6}>
                    {scanResult && (
                        <Card className="mt-4">
                            <Card.Header>
                                <h2>nmap scan results</h2>
                            </Card.Header>
                            <Card.Body>
                                <pre>{scanResult}</pre>
                            </Card.Body>
                        </Card>
                    )}
                    {error && (
                        <div className="mt-4">
                            <h2>Error</h2>
                            <pre>{error}</pre>
                        </div>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default NmapScan;
