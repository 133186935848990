import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Container, Row, Col, Form, Button, Accordion, Card, InputGroup, FormControl, FormCheck, FormGroup } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import firebase from 'firebase/app';
import 'firebase/database';
import Papa from 'papaparse';

const firebaseConfig = {
    apiKey: "YOUR_API_KEY",
    authDomain: "YOUR_AUTH_DOMAIN",
    databaseURL: "YOUR_DATABASE_URL",
    projectId: "YOUR_PROJECT_ID",
    storageBucket: "YOUR_STORAGE_BUCKET",
    messagingSenderId: "YOUR_MESSAGING_SENDER_ID",
    appId: "YOUR_APP_ID"
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
} else {
    firebase.app();
}

const Contacts = () => {
    const [contacts, setContacts] = useState([]);
    const [monitors, setMonitors] = useState({});
    const [newContact, setNewContact] = useState({ fullName: '', title: '', phone: '', email: '', office: '', notes: '', department: 'Unassigned' });

    useEffect(() => {
        const contactsRef = firebase.database().ref('contacts');
        contactsRef.on('value', (snapshot) => {
            const data = snapshot.val();
            if (data) {
                setContacts(Object.values(data));
            } else {
                setContacts([]);
            }
        });

        const monitorsRef = firebase.database().ref('/kuma/monitor_status').orderByKey();
        monitorsRef.on('value', (snapshot) => {
            const monitorsData = {};
            snapshot.forEach(childSnapshot => {
                const key = childSnapshot.key;
                if (!key.startsWith("California") && !key.startsWith("East")) {
                    monitorsData[key] = key; // Filter out monitors starting with "California" or "East"
                }
            });
            setMonitors(monitorsData);
        });
    }, []);

    const handleMonitorChange = (contactId, monitorId, isChecked) => {
        const contactRef = firebase.database().ref('contacts').child(contactId).child('monitors').child(monitorId);
        if (isChecked) {
            contactRef.set(true);
        } else {
            contactRef.remove();
        }
    };

    const handleOfficeChange = (contactId, officeValue) => {
        const contactRef = firebase.database().ref('contacts').child(contactId);
        contactRef.update({ office: officeValue });
    };

    const renderMonitors = (contact) => {
        return (
            <Accordion>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                        Properties Assigned | Click to Expand
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body style={{ fontFamily: "courier" }}>
                            <Row xs={1} md={1} className="px-1">
                                {Object.entries(monitors).map(([id, name]) => (
                                    <FormCheck
                                        key={id}
                                        type="checkbox"
                                        label={name}
                                        checked={contact.monitors && contact.monitors[id]}
                                        onChange={(e) => handleMonitorChange(contact.id, id, e.target.checked)}
                                    />
                                ))}
                            </Row>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        );
    };

    const renderOffices = (contact) => {
        const offices = ["Atrium", "BioVista", "BioTerra", "Boston", "Durham", "Icona", "Icona/Redwood Life", "Inspire Eng Office", "Palo Alto Labs", "Redwood Life", "Roney Street", "San Diego"];
        return (
            <Accordion>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="1">
                        Local Office | Click to Expand
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body>
                            <Row xs={1} md={2} className="px-1">
                                {offices.map(office => (
                                    <FormCheck
                                        key={office}
                                        type="radio"
                                        name={`offices-${contact.id}`}
                                        label={office}
                                        checked={contact.office === office}
                                        onChange={() => handleOfficeChange(contact.id, office)}
                                    />
                                ))}
                            </Row>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        );
    };

    const saveContactToFirebase = (contact) => {
        const contactsRef = firebase.database().ref('contacts');
        const newContactRef = contactsRef.push();
        contact.id = newContactRef.key;
        newContactRef.set(contact);
    };

    const updateContactInFirebase = (id, key, value) => {
        const contactRef = firebase.database().ref('contacts').child(id);
        contactRef.update({ [key]: value });
    };

    const deleteContactFromFirebase = (id) => {
        const contactRef = firebase.database().ref('contacts').child(id);
        contactRef.remove();
    };

    const handleAddContact = (event) => {
        event.preventDefault();
        const newContactObject = {
            id: uuidv4(),
            ...newContact,
            createdAt: new Date().toLocaleString(),
        };
        saveContactToFirebase(newContactObject);
        setNewContact({ fullName: '', title: '', phone: '', email: '', office: '', notes: '', department: 'Unassigned', monitors: {} });
    };

    const handleUpdateContact = (id, key, value) => {
        setContacts(contacts.map(contact => (contact.id === id ? { ...contact, [key]: value } : contact)));
        updateContactInFirebase(id, key, value);
    };

    const handleDeleteContact = (id) => {
        setContacts(contacts.filter(contact => contact.id !== id));
        deleteContactFromFirebase(id);
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;
        const { source, destination } = result;

        const updatedContacts = Array.from(contacts);
        const [movedContact] = updatedContacts.splice(source.index, 1);
        updatedContacts.splice(destination.index, 0, movedContact);

        setContacts(updatedContacts);
        updateContactInFirebase(result.draggableId, 'department', destination.droppableId);
    };

    const exportContactsToCSV = () => {
        const csv = Papa.unparse({
            fields: ["fullName", "title", "phone", "email", "office", "notes", "department", "createdAt", "monitors"],
            data: contacts.map(contact => ({
                ...contact,
                monitors: JSON.stringify(contact.monitors),
                id: undefined
            }))
        });
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'contacts.csv';
        link.click();
        URL.revokeObjectURL(url);
    };

    const handleImportContacts = event => {
        const file = event.target.files[0];
        if (file) {
            Papa.parse(file, {
                header: true,
                complete: results => {
                    const importedContacts = results.data.map(contact => ({
                        ...contact,
                        monitors: contact.monitors ? JSON.parse(contact.monitors) : {},
                        createdAt: contact.createdAt || new Date().toISOString()
                    }));

                    importedContacts.forEach(contact => {
                        const existingContact = contacts.find(c => c.email === contact.email);
                        if (existingContact) {
                            updateContactInFirebase(existingContact.id, contact);
                        } else {
                            saveContactToFirebase({ ...contact, id: uuidv4() });
                        }
                    });

                    setContacts(prevContacts => [
                        ...prevContacts.filter(c => !importedContacts.some(ic => ic.email === c.email)),
                        ...importedContacts
                    ]);
                }
            });
        }
    };

    const contactForm = (
        <Form onSubmit={handleAddContact} className="py-3 my-3 px-3 bg-dark text-light rounded shadow-sm">
            <Row>
                <Col md={3}>
                    <h5>Full Name - First & Last</h5>
                    <FormControl
                        type="text"
                        placeholder="Full Name"
                        value={newContact.fullName}
                        onChange={(e) => setNewContact({ ...newContact, fullName: e.target.value })}
                    />
                </Col>
                <Col md={3}>
                    <h5>Title / Position</h5>
                    <FormControl
                        type="text"
                        placeholder="Title"
                        value={newContact.title}
                        onChange={(e) => setNewContact({ ...newContact, title: e.target.value })}
                    />
                </Col>
                <Col md={3}>
                    <h5>Phone Number - Work Cell</h5>
                    <FormControl
                        type="text"
                        placeholder="Phone"
                        value={newContact.phone}
                        onChange={(e) => setNewContact({ ...newContact, phone: e.target.value })}
                    />
                </Col>
                <Col md={3}>
                    <h5>Email Address - @LFREP.com (or other)</h5>
                    <FormControl
                        type="email"
                        placeholder="Email"
                        value={newContact.email}
                        onChange={(e) => setNewContact({ ...newContact, email: e.target.value })}
                    />
                </Col>
            </Row>
            <Row className="py-2 text-dark d-none">
                <Col md={3}>
                    <h5 className="text-light">Local Office - Select One</h5>
                    {renderOffices(newContact)}
                </Col>
                <Col md={9}>
                    <h5 className="text-light">Properties Assigned - Select Multiple (as needed)</h5>
                    {renderMonitors(newContact)}
                </Col>
            </Row>
            <Row className="py-2">
                <Col>
                    <h5 className="text-light">Notes</h5>
                    <FormControl
                        as="textfield"
                        placeholder="Notes"
                        value={newContact.notes}
                        onChange={(e) => setNewContact({ ...newContact, notes: e.target.value })}
                    />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col className="text-center">
                    <Button variant="secondary" onClick={exportContactsToCSV}>Export Contacts</Button>
                    <input
                        type="file"
                        accept=".csv"
                        onChange={handleImportContacts}
                        style={{ display: 'none' }}
                        id="import-file-input"
                    />
                </Col>
                <Col className="text-center">
                    <Button type="submit">Add Contact</Button>
                </Col>
                <Col className="text-center">
                    <Button variant="secondary" onClick={() => document.getElementById('import-file-input').click()}>Import Contacts</Button>
                </Col>
            </Row>
        </Form>
    );

    const contactList = (department) => (
        <Droppable droppableId={department}>
            {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                    <Accordion>
                        {contacts.filter(contact => contact.department === department).map((contact, index) => (
                            <Draggable key={contact.id} draggableId={contact.id} index={index} >
                                {(provided) => (
                                    <Card ref={provided.innerRef} {...provided.draggableProps}>
                                        <div {...provided.dragHandleProps} className="drag-handle">
                                            <span className="mr-2 cursor-pointer"><span style={{ paddingLeft: "0.5em" }}>☰ <small className="text-secondary float-right pr-2 pt-1">click to view more info</small></span> <small style={{ float: "right", paddingRight: "0.5em" }} className="d-none">Created at: {contact.createdAt}</small></span>
                                        </div>
                                        <Accordion.Toggle as={Card.Header} eventKey={String(contact.id)} className="text-light">
                                            {contact.fullName}&nbsp;@&nbsp;{contact.office}
                                            <div className="float-right d-none">
                                                <Button variant="link" size="sm" onClick={() => handleDeleteContact(contact.id)}>
                                                    Delete
                                                </Button>
                                            </div>
                                            <div className="float-right d-none">
                                                <Button variant="link" size="sm" XonClick={() => handleDeleteContact(contact.id)}>
                                                    view more
                                                </Button>
                                            </div>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey={String(contact.id)}>
                                            <Card.Body>
                                                <InputGroup>
                                                    <Container>
                                                        <Row className="py-2">
                                                            <Col md={6}>
                                                                <FormControl
                                                                    type="text"
                                                                    value={contact.fullName}
                                                                    onChange={(e) => handleUpdateContact(contact.id, 'fullName', e.target.value)}
                                                                    placeholder="Full Name"
                                                                />
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormControl
                                                                    type="text"
                                                                    value={contact.title}
                                                                    onChange={(e) => handleUpdateContact(contact.id, 'title', e.target.value)}
                                                                    placeholder="Title"
                                                                />
                                                            </Col>                                                            
                                                        </Row>
                                                        <Row className="py-2">
                                                            
                                                            <Col md={6}>
                                                                <FormControl
                                                                    type="text"
                                                                    value={contact.phone}
                                                                    onChange={(e) => handleUpdateContact(contact.id, 'phone', e.target.value)}
                                                                    placeholder="Phone"
                                                                />
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormControl
                                                                    type="email"
                                                                    value={contact.email}
                                                                    onChange={(e) => handleUpdateContact(contact.id, 'email', e.target.value)}
                                                                    placeholder="Email"
                                                                />
                                                            </Col>

                                                        </Row>
                                                        <Row className="py-2">
                                                            <Col>
                                                                <FormControl
                                                                    as="textarea"
                                                                    value={contact.notes}
                                                                    onChange={(e) => handleUpdateContact(contact.id, 'notes', e.target.value)}
                                                                    placeholder="Notes"
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="py-2">
                                                            <Col>{renderOffices(contact)}</Col>
                                                        </Row>
                                                        <Row className="py-2">
                                                            <Col>{renderMonitors(contact)}</Col>
                                                        </Row>
                                                    </Container>
                                                </InputGroup>
                                                <small>Changes are saved automatically</small>
                                                <div className="float-right Xd-none">
                                                    <Button variant="danger" size="sm" onClick={() => handleDeleteContact(contact.id)}>
                                                        Delete
                                                    </Button>
                                                </div>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </Accordion>
                </div>
            )}
        </Droppable>
    );

    return (
        <Container fluid>
            <Row>
                <Col>
                    <h4>Contact Management</h4>
                    {contactForm}
                </Col>
            </Row>
            <DragDropContext onDragEnd={onDragEnd}>
                <Row className="mb-3">
                    <Col md={4}>
                        <Card>
                            <Card.Header className="text-light text-uppercase">Unassigned</Card.Header>
                            <Card.Body>{contactList('Unassigned')}</Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card>
                            <Card.Header className="text-light text-uppercase">IT / AV / Security</Card.Header>
                            <Card.Body>{contactList('IT / AV')}</Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card>
                            <Card.Header className="text-light text-uppercase">Elevate</Card.Header>
                            <Card.Body>{contactList('Elevate')}</Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={4}>
                        <Card>
                            <Card.Header className="text-light text-uppercase">Marketing</Card.Header>
                            <Card.Body>{contactList('Marketing')}</Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card>
                            <Card.Header className="text-light text-uppercase">Accounting</Card.Header>
                            <Card.Body>{contactList('Accounting')}</Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card>
                            <Card.Header className="text-light text-uppercase">Asset Management</Card.Header>
                            <Card.Body>{contactList('Asset Management')}</Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={4}>
                        <Card>
                            <Card.Header className="text-light text-uppercase">Property Management</Card.Header>
                            <Card.Body>{contactList('Property Management')}</Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card>
                            <Card.Header className="text-light text-uppercase">Design & Construction</Card.Header>
                            <Card.Body>{contactList('Design & Construction')}</Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card>
                            <Card.Header className="text-light text-uppercase">Engineering</Card.Header>
                            <Card.Body>{contactList('Engineering')}</Card.Body>
                        </Card>
                    </Col>
                </Row>
            </DragDropContext>
        </Container>
    );
};

export default Contacts;