import React, { useState, useEffect } from 'react';
import { Table, Container } from 'react-bootstrap';
import firebase from 'firebase/app';
import 'firebase/database';

const SiteContacts = ({ monitor }) => {
    const [contacts, setContacts] = useState([]);

    useEffect(() => {
        const contactsRef = firebase.database().ref('contacts');
        contactsRef.on('value', (snapshot) => {
            const data = snapshot.val();
            if (data) {
                const contactsArray = Object.values(data);
                const filteredContacts = contactsArray.filter(contact => contact.monitors && contact.monitors[monitor]);
                setContacts(filteredContacts.sort((a, b) => a.fullName.localeCompare(b.fullName)));
            } else {
                setContacts([]);
            }
        });

        return () => contactsRef.off();
    }, [monitor]);

    const groupedContacts = contacts.reduce((acc, contact) => {
        const dept = contact.department || 'Unassigned';
        if (!acc[dept]) acc[dept] = [];
        acc[dept].push(contact);
        return acc;
    }, {});

    return (
        <Container fluid>
            {Object.entries(groupedContacts).map(([department, deptContacts]) => (
                <div key={department} className="my-3">
                    <h5>{department}</h5>
                    <Table responsive bordered hover>
                        <thead>
                            <tr>
                                <th>Full Name</th>
                                <th>Title</th>
                                <th>Phone</th>
                                <th>Email</th>
                                <th>Office</th>
                            </tr>
                        </thead>
                        <tbody>
                            {deptContacts.map(contact => (
                                <tr key={contact.id}>
                                    <td>{contact.fullName}</td>
                                    <td>{contact.title}</td>
                                    <td>{contact.phone}</td>
                                    <td>{contact.email}</td>
                                    <td>{contact.office}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            ))}
        </Container>
    );
};

export default SiteContacts;
