import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import { Container, Row, Col, Form } from 'react-bootstrap';

export default function NetworkStatus() {
    const [networks, setNetworks] = useState([]);
    const [selectedTimestamp, setSelectedTimestamp] = useState('');
    const [timestamps, setTimestamps] = useState([]);

    useEffect(() => {
        const dbRef = firebase.database().ref('/network-devices');
        dbRef.on('value', snapshot => {
            let networkList = [];
            let timestampList = [];
            snapshot.forEach(serverTimestamp => {
                timestampList.push(serverTimestamp.key);
                if (serverTimestamp.key === selectedTimestamp) {
                    serverTimestamp.forEach(network => {
                        let appliances = 0, switches = 0, wireless = 0, clientCount = 0, guestClients = 0, cellularGateways = 0;
                        network.forEach(productType => {
                            productType.forEach(serial => {
                                const data = serial.val();
                                if (data.productType === 'appliance') appliances++;
                                if (data.productType === 'switch') switches++;
                                if (data.productType === 'wireless') wireless++;
                                if (data.productType === 'cellularGateway') cellularGateways++;
                                clientCount += parseInt(data.clientCount || '0', 10);
                                guestClients += parseInt(data.guestClients || '0', 10);
                            });
                        });
                        networkList.push({
                            networkName: network.key,
                            cellularGateways,
                            appliances,
                            switches,
                            wireless,
                            clientCount,
                            guestClients,
                            wan1Status: network.child('wan1_status').val(),
                            wan2Status: network.child('wan2_status').val(),
                            highAvailability: network.child('highAvailability').val(),
                            timestamp: serverTimestamp.key
                        });
                    });
                }
            });
            setTimestamps(timestampList.sort((a, b) => b.localeCompare(a)));
            setNetworks(networkList);
            if (!selectedTimestamp) {
                setSelectedTimestamp(timestampList[0]);
            }
        });

        return () => dbRef.off();
    }, [selectedTimestamp]);

    const handleTimestampChange = (event) => {
        setSelectedTimestamp(event.target.value);
    };

    const formatTimestamp = (timestamp) => {
        // Assuming timestamp format is "YYYYMMDDHHMMSSSSSS" as UTC
        const year = parseInt(timestamp.slice(0, 4), 10);
        const month = parseInt(timestamp.slice(4, 6), 10);
        const day = parseInt(timestamp.slice(6, 8), 10);
        const hour = parseInt(timestamp.slice(8, 10), 10);
        const minute = parseInt(timestamp.slice(10, 12), 10);
        const second = parseInt(timestamp.slice(12, 14), 10);
        const date = new Date(Date.UTC(year, month - 1, day, hour, minute, second));
        return `${month}/${day}/${year} ${hour}:${minute.toString().padStart(2, '0')}:${second.toString().padStart(2, '0')} UTC`;
    };

    return (
        <Container fluid>
            <Row className="mb-3">
                <Col>
                    {/* Change timestamp here */}
                    <Form.Control as="select" value={selectedTimestamp} onChange={handleTimestampChange}>
                        {timestamps.map((timestamp, index) => (
                            <option key={index} value={timestamp}>{formatTimestamp(timestamp)}</option>
                        ))}
                    </Form.Control>
                </Col>
            </Row>
            <Row className="mb-3 bg-dark text-light">
                <Col xs={8} md={6} lg={3}>networkName</Col>
                {/* <Col xs={4} md={3} lg={2}>highAvailability</Col> */}
                <Col xs={4} md={3} lg={1}>cellularGateways</Col>
                <Col xs={3} md={3} lg={1}>appliances</Col>
                <Col xs={3} md={3} lg={1}>switches</Col>
                <Col xs={3} md={3} lg={1}>wireless</Col>
                <Col xs={6} md={3} lg={2}>wan1Status</Col>
                <Col xs={6} md={3} lg={2}>wan2Status</Col>
                <Col xs={12} md={3} lg={1}>timestamp</Col>
            </Row>

            {networks.map((network, index) => (
                <Row key={index} className="mb-3">
                    <Col xs={8} md={6} lg={3}>{network.networkName}</Col>
                    {/* <Col xs={4} md={3} lg={2}>{network.highAvailability}</Col> */}
                    <Col xs={3} md={3} lg={1}>{network.cellularGateways}</Col>
                    <Col xs={3} md={3} lg={1}>{network.appliances}</Col>
                    <Col xs={3} md={3} lg={1}>{network.switches}</Col>
                    <Col xs={3} md={3} lg={1}>{network.wireless}</Col>
                    <Col xs={6} md={3} lg={2}>{network.wan1Status}</Col>
                    <Col xs={6} md={3} lg={2}>{network.wan2Status}</Col>
                    <Col xs={12} md={3} lg={1}>{formatTimestamp(network.timestamp)}</Col>
                </Row>
            ))}
        </Container>
    );
}
