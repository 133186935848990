

import React from 'react'
import ReactGA from 'react-ga'
import { Container, Jumbotron, Button, Row, Col } from 'react-bootstrap'
import Properties from './Properties';
import Viewer from './Viewer';
import MasterList from './MasterList';

//import { useAuth } from "../contexts/FirebaseAuth"

export default function Home() {
    //const { currentUser } = useAuth()

    function handleClick(event) {
        console.log("YOU CLICKED TO LEARN MORE!")
        ReactGA.event({ category: "Learn More Button", action: "Click" })
        // do something here...
    }

    return (
        <>
            {/* <Container Fluid>
                <h1>Home</h1>

                <p className="lead">Welcome home...</p>

                <Jumbotron>
                    <h2>Hello, world!</h2>
                    <p>
                        This is a simple hero unit, a simple jumbotron-style component for calling
                        extra attention to featured content or information.
                    </p>
                    <p>
                        <Button variant="primary" onClick={handleClick}>Learn more</Button>
                    </p>
                </Jumbotron>
            </Container>

            <Container fluid className="">
                <Properties />
            </Container> */}

            <Container fluid className="bg-gradient bg-primary rounded my-3 mx-0 px-0 mr-0 ml-o pr-0 pl-0 text-center">
                <Row className="py-2">
                    <Col>
                        <i class="bi bi-award-fill text-light"></i>
                    </Col>
                    <Col xs={12} md={10} className="text-center text-light">
                        future spot for date & time | welcome messages/notices here
                    </Col>
                    <Col>
                        <i class="bi bi-award-fill text-light"></i>
                    </Col>
                </Row>
            </Container>

            <Container fluid className="">
                <Viewer />
            </Container>


        </>
    )
}
