import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import { Container, Row, Col, Card } from 'react-bootstrap';

const firebaseConfig = {
    // Add your Firebase config here
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
} else {
    firebase.app();
}

const OutagesComponent = () => {
    const [outages, setOutages] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const outagesRef = firebase.database().ref('outages');
        outagesRef.on('value', (snapshot) => {
            const data = snapshot.val();
            if (data) {
                const filteredOutages = Object.values(data).filter(outage =>
                    outage.locations &&
                    outage.locations.some(loc => ['US', 'MX', 'UK', 'CA'].includes(loc))
                );
                setOutages(filteredOutages);
            } else {
                setOutages([]);
            }
            setLoading(false);
        });
    }, []);

    return (
        <Container fluid>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <>
                    <Row>
                        <Col xs={12} md={12} lg={6} className="h4 text-left">
                            Outages | Reported via Cloudflare
                        </Col>

                        <Col xs={12} md={12} lg={6} className="h6 text-right text-uppercase text-info">
                            <p>Currently monitoring {outages.length} outages (US, MX, UK, CA)</p>
                        </Col>
                    </Row>
                    <Row>
                        {outages.map((outage, index) => (
                            <Col md={4} key={index}>
                                <Card className="mb-3">
                                    <Card.Header>
                                        <strong>Outage ID: <span className="text-white text-bold">{outage.id}</span></strong><br />
                                        <Row>
                                            <Col>Description: <span className="text-white">{outage.description}</span></Col>
                                        </Row>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col>
                                                <h5>Details</h5>
                                                <p>Start Date: {new Date(outage.startDate).toLocaleString()}</p>
                                                <p>End Date: {new Date(outage.endDate).toLocaleString()}</p>
                                                <p>Event Type: {outage.eventType}</p>
                                                {outage.locations && <p>Locations: {outage.locations.join(', ')}</p>}
                                                <p>More Info: <a href={outage.linkedUrl} target="_blank" rel="noopener noreferrer">Link</a></p>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </>
            )}
        </Container>
    );
};

export default OutagesComponent;
