import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import axios from 'axios';
import { Container, Row, Col, Form, Card, Table, Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

function NetworkDetails() {
    const [networkData, setNetworkData] = useState({});
    const [statusMatrix, setStatusMatrix] = useState({});
    const [additionalInfo, setAdditionalInfo] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [latestTimestamp, setLatestTimestamp] = useState('');

    const location = useLocation();

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const searchQuery = query.get('search');
        if (searchQuery) {
            setSearchTerm(searchQuery);
        }

        const networkRef = firebase.database().ref('/network-devices');
        networkRef.on('value', snapshot => {
            const data = snapshot.val() || {};
            const latestTimestamp = Object.keys(data).sort((b, a) => a.localeCompare(b))[0]; // Get the earliest timestamp
            const networks = data[latestTimestamp] || {};
            setNetworkData(networks);
            setLatestTimestamp(latestTimestamp); // Update the latest timestamp

            // Compute status matrix
            const matrix = {};
            Object.values(networks).forEach(network => {
                ['cellularGateway', 'appliance', 'switch', 'wireless'].forEach(type => {
                    if (network[type]) {
                        Object.values(network[type]).forEach(device => {
                            matrix[type] = matrix[type] || {};
                            matrix[type][device.status] = (matrix[type][device.status] || 0) + 1;
                        });
                    }
                });
            });
            setStatusMatrix(matrix);

            // Load additional information
            const kumaRef = firebase.database().ref('/kuma');
            kumaRef.on('value', kumaSnapshot => {
                const kumaData = kumaSnapshot.val() || {};
                const additional = {};
                Object.keys(networks).forEach(key => {
                    const network = networks[key];
                    const publicIp = network.appliance ? network.appliance[Object.keys(network.appliance)[0]].wan1Ip || network.appliance[Object.keys(network.appliance)[0]].wan2Ip : null;
                    const fallbackIp = findFallbackIp(network);
                    const ipToUse = publicIp || fallbackIp;
                    if (ipToUse) {
                        const kumaKey = Object.keys(kumaData.monitor_response_time).find(k => kumaData.monitor_response_time[k].monitor_hostname === ipToUse);
                        if (kumaKey) {
                            additional[key] = {
                                status: kumaData.monitor_status[kumaKey] ? kumaData.monitor_status[kumaKey].value : 'Unknown',
                                monitorName: kumaData.monitor_status[kumaKey] ? kumaData.monitor_status[kumaKey].monitor_name : 'Unknown',
                                responseTime: kumaData.monitor_response_time[kumaKey] ? kumaData.monitor_response_time[kumaKey].value : 'Unknown'
                            };
                        }
                    }
                });
                setAdditionalInfo(additional);
            });
        });

        return () => {
            networkRef.off();
            firebase.database().ref('/kuma').off();
        };
    }, [location.search]);

    const formatTimestamp = (timestamp) => {
        // Assuming timestamp format is "YYYYMMDDHHMMSSSSSS" as UTC
        const year = parseInt(timestamp.slice(0, 4), 10);
        const month = parseInt(timestamp.slice(4, 6), 10);
        const day = parseInt(timestamp.slice(6, 8), 10);
        const hour = parseInt(timestamp.slice(8, 10), 10);
        const minute = parseInt(timestamp.slice(10, 12), 10);
        const second = parseInt(timestamp.slice(12, 14), 10);
        const date = new Date(Date.UTC(year, month - 1, day, hour, minute, second));
        // return `${month}/${day}/${year} ${hour}:${minute.toString().padStart(2, '0')}:${second.toString().padStart(2, '0')} UTC`;
        // return `${year}-${month}-${day} ${hour}:${minute.toString().padStart(2, '0')}:${second.toString().padStart(2, '0')} UTC`;
        return `{"d2":"${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}T${hour.toString().padStart(2, '0') }:${minute.toString().padStart(2, '0')}:${second.toString().padStart(2, '0')}.000Z"}`;
    };

    const currentTime = { d1: new Date() }
    // console.log(currentTime.d1.toString()) //Mon Jan 02 2023 20:37:28 GMT-0800 (Pacific Standard Time)
    // console.log(JSON.stringify(currentTime)) //{"dd":"2023-01-03T04:37:28.000Z"}`
    // currentTime.d1 = currentTime.d1.toString() // -> You can fix this issue by storing string in your json
    // console.log(JSON.stringify(currentTime)) //{"d1":"Mon Jan 02 2023 20:37:28 GMT-0800 (Pacific Standard Time)"}

    const renderDevices = (devices, deviceType) => {
        const includeLanIp = ['switch', 'wireless'].includes(deviceType);
        return devices ? Object.values(devices).map((device, index) => (
            <Row key={deviceType + index} className="border-bottom mb-1">
                <Col xs={12} md={6} lg={6} className="text-left mx-0" style={{textAlign: "left !important"}}>
                    <Button variant="link" size="lg" href={`https://api.meraki.com/manage/dashboard/show?mac=${device.mac}`} target="_blank" className="py-0 my-0 mx-0 px-0" style={{ fontFamily: "Verdana", fontSize: ".8em" }}><i class="bi bi-box-arrow-up-right "></i>&nbsp;{device.name}</Button>
                </Col>

                <Col xs={4} md={3} lg={3} className="text-left" style={{ fontFamily: "Verdana", fontSize: ".8em" }}>{device.model}</Col>

                <Col xs={4} md={4} lg={3} style={{ fontFamily: "Verdana", fontSize: "0.75em", textTransform: "uppercase", textAlign: "right" }}>{device.mac}</Col>

                <Col xs={4} md={3} lg={3} className="text-left" style={{ fontFamily: "Courier", textTransform: "uppercase", fontWeight: "bold" }}> <span style={{ color: getDeviceColor(device.status) }}>{device.status}</span></Col>

                <Col xs={4} md={3} lg={3} className="text-left" style={{ fontFamily: "Courier", fontSize: "0.8em", textAlign: "left", textTransform: "uppercase", fontWeight: "normal" }}><span style={{ color: getIpTypeColor(device.ipType) }}>{device.ipType}</span></Col>

                {includeLanIp && <Col xs={4} md={3} lg={3} style={{ fontFamily: "courier", fontSize: "0.8em" }}>{device.lanIp}</Col>}

                {includeLanIp && <Col xs={4} md={3} lg={3} style={{ fontFamily: "courier", fontSize: "0.75em", textAlign: "right" }}>DNS:{device.primaryDns}</Col>}
            </Row>
        )) : <Row><Col>No {deviceType} available</Col></Row>;
    };

    const renderStatusMatrix = () => {
        const exampleData = {
            cellularGateway: { online: 10, alerting: 0, offline: 0, dormant: 0 },
            appliance: { online: 37, alerting: 0, offline: 0, dormant: 0 },
            switch: { online: 51, alerting: 0, offline: 0, dormant: 2 },
            wireless: { online: 151, alerting: 1, offline: 0, dormant: 16 }
        };

        return (
            <Table striped bordered hover size="sm" className="bg-gradient text-dark mt-2" style={{ fontSize: "0.85em" }}>
                <thead>
                    <tr>
                        <th>&nbsp;</th>
                        {Object.keys(exampleData).map(type => <th key={type}>{type}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(exampleData['cellularGateway']).map(status => (
                        <tr key={status}>
                            <td>{status}</td>
                            {Object.keys(exampleData).map(type => (
                                <td key={type + status}>
                                    {statusMatrix[type] && statusMatrix[type][status] ? `${statusMatrix[type][status]} / ${exampleData[type][status]}` : `0 / ${exampleData[type][status]}`}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </Table>
        );
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const filterNetworks = () => {
        if (searchTerm.length < 2) return Object.keys(networkData);

        return Object.keys(networkData).filter(key => {
            const network = networkData[key];
            const searchData = [
                key,
                network.wan1_status,
                network.wan2_status,
                ...(network.appliance ? Object.values(network.appliance).flatMap(device => [
                    device.name,
                    device.wan1Ip,
                    device.wan2Ip,
                    device.status,
                    device.model,
                    device.mac,
                    device.ipType,
                    device.primaryDns
                ]) : []),
                ...(network.cellularGateway ? Object.values(network.cellularGateway).flatMap(device => [
                    device.name,
                    device.lanIp,
                    device.status,
                    device.model,
                    device.mac,
                    device.ipType,
                    device.primaryDns
                ]) : []),
                ...(network.switch ? Object.values(network.switch).flatMap(device => [
                    device.name,
                    device.lanIp,
                    device.status,
                    device.model,
                    device.mac,
                    device.ipType,
                    device.primaryDns
                ]) : []),
                ...(network.wireless ? Object.values(network.wireless).flatMap(device => [
                    device.name,
                    device.lanIp,
                    device.status,
                    device.model,
                    device.mac,
                    device.ipType,
                    device.primaryDns
                ]) : [])
            ];

            return searchData.some(data => data && data.toLowerCase().includes(searchTerm.toLowerCase()));
        });
    };

    const filteredNetworks = filterNetworks();

    const parseHighAvailability = (highAvailability) => {
        try {
            return JSON.parse(highAvailability).role;
        } catch (error) {
            return 'N/A';
        }
    };

    {/* const dialNumber = (number) => {
        window.open(`tel:${number}`);
    }; */}

    const dialNumber = (number) => {
        //window.open(`href:"tel:"${number}`);
        return <a href={`tel:${number}`}></a>;
        // href = "tel:+18475555555"
    };

    return (
        <>
        <Container fluid className="mx-0 px-0 Xbg-white">
            <Row className="Xbg-white Xborder-bottom">
                <Col xs={12} md={6} lg={4}>
                        <center><Form.Label htmlFor="inputSearch5" className="h4"><i class="bi bi-binoculars-fill text-warning"></i> Meraki Network Instant Search <i class="bi bi-binoculars-fill text-warning"></i></Form.Label></center>
                    <Form.Control
                        type="search"
                        value={searchTerm}
                        placeholder="start typing here - 2 characters minimum"
                        onChange={handleSearchChange}
                        aria-describedby="searchHelpBlock"
                        className="shadow-sm text-dark"
                    />

                    <Form.Text id="searchHelpBlock" className="h6" muted>
                        <Row>
                                <Col xs={12} md={12} lg={12} className="mb-2 text-justify">Search for anything you can see on this page. Network Name, WAN Information, Status Information, Device Information. If you can see it, you can search it. Current time to complete: 1min (dependent on host).&nbsp;<span className="text-info">GOAL: Updated every 60 seconds. CURRENT: Updated every 20 minutes; LIMIT: 12 hours history for network devices.</span><span className="text-justify"> </span></Col>
                        </Row>
                        <Row>
                            <Col style={{ fontFamily: "courier" }}>
                                <Row>
                                    <Col xs={12} md={4} lg={4}>Current UTC:</Col>
                                    <Col xs={12} md={4} lg={8} className="text-right">{JSON.stringify(currentTime)}</Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={4} lg={4}>Last Update:</Col>
                                    <Col xs={12} md={12} lg={8} className="d-noneX text-right">{formatTimestamp(latestTimestamp)}</Col>
                                    <Col xs={12} md={12} lg={8} className="d-none text-right">{latestTimestamp.toString()}</Col>
                                </Row>
                            </Col>
                        </Row>
                        <span className="text-dark h3 d-none">Last Update Time: {formatTimestamp(latestTimestamp)}</span>
                    </Form.Text>
                </Col>
                <Col xs={12} md={6} lg={4}>
                        <center><span className="h4"><i class="mb-2 bi bi-router-fill text-warning"></i> Meraki Device Inventory Counter <i class="bi bi-wifi text-warning"></i></span></center>
                    {renderStatusMatrix()}
                    </Col>
                <Col xs={12} md={6} lg={4}>
                    <div class="alert alert-dismissible alert-secondary">
                        <button type="button" class="btn-close d-none" data-bs-dismiss="alert"></button>
                        <h4 class="alert-heading text-uppercase">Future Improvements</h4>
                        <p class="mb-0">
                            Toggles Go Here for Easy Display Changes<br />
                            - toggle for show/hide devices by "status"<br />
                            - toggle for show/hide devices by "ipType"<br />
                            - toggle for show/hide device by "region" using first 4 characters of "additionalInfo[networkKey].monitorName" to group like regions<br />
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
        <Container fluid className="px-0 mx-0">
            {/* <Row>
                <Col>
                    <Form.Control as="select" value={selectedNetwork} onChange={handleNetworkChange}>
                        {Object.keys(networkData).map((networkKey, index) => (
                            <option key={index} value={networkKey}>{networkKey}</option>
                        ))}
                    </Form.Control>
                </Col>
            </Row> */}
            

            {filteredNetworks.map((networkKey) => (
                <React.Fragment key={networkKey}>
                    <Row className="bg-gradient bg-dark text-light py-2 my-2">
                        <Col xs={12} md={12} lg={4} className="" style={{ color: "whitesmoke" }}>
                            <Row xs={4} md={4} lg={4}>
                                <Col xs={12} md={6} lg={12} className="text-left h3 mb-0">
                                    {networkKey}
                                </Col>
                                <Col xs={12} md={6} lg={12} style={{ fontSize: "1em"}} className="py-0 my-0">
                                    <span className="text-warning">{additionalInfo[networkKey] ? `Response Time @  ${additionalInfo[networkKey].responseTime} ms` : ''}</span><br />
                                    <span className="text-success">{additionalInfo[networkKey] ? `${additionalInfo[networkKey].monitorName}` : ''}</span>
                                </Col>
                            </Row>
                            {/* {additionalInfo[networkKey] ? `${additionalInfo[networkKey].status} - ${additionalInfo[networkKey].responseTime}` : 'No UptimeKuma Monitor Found'}
                            {additionalInfo[networkKey] ? `${additionalInfo[networkKey].status}` : ''}
                            {additionalInfo[networkKey] ? ` ${additionalInfo[networkKey].responseTime}` : ''} */}
                        </Col>
                        {/* <Col xs={8} md={6} lg={1}><h4>{parseHighAvailability(currentNetwork.highAvailability)}</h4></Col> */}
                        <Col xs={12} md={12} lg={3}>
                            <Row xs={4} md={4} lg={4} className="text-left h4">
                                <Col xs={12} md={6} lg={12}>
                                    <span className="text-info">WAN1</span>&nbsp;<span style={{ color: getColor(networkData[networkKey].wan1_status), textTransform: "uppercase" }}>&#11044;&nbsp;{networkData[networkKey].wan1_status || 'N/A'}</span>
                                </Col>
                                <Col xs={12} md={6} lg={12}>
                                    <span className="text-info">WAN1 IP </span><span style={{ fontFamily: "courier", fontWeight: "bold" }}>{networkData[networkKey].appliance ? networkData[networkKey].appliance[Object.keys(networkData[networkKey].appliance)[0]].wan1Ip : findFallbackIp(networkData[networkKey])}</span>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={12} lg={3}>
                            <Row xs={4} md={4} lg={4} className="text-left h4">
                                <Col xs={12} md={6} lg={12}>
                                    <span className="text-info">WAN2</span>&nbsp;<span style={{ color: getColor(networkData[networkKey].wan2_status), textTransform: "uppercase" }}>&#11044;&nbsp;{networkData[networkKey].wan2_status || 'N/A'}</span>
                                </Col>
                                <Col xs={12} md={6} lg={12}>
                                    <span className="text-info">WAN2 IP </span><span style={{ fontFamily: "courier", fontWeight: "bold" }}>{networkData[networkKey].appliance ? networkData[networkKey].appliance[Object.keys(networkData[networkKey].appliance)[0]].wan2Ip : findFallbackIp(networkData[networkKey])}</span>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={12} lg={2}>
                            <Row xs={4} md={4} lg={4} className="text-center">
                                <Col xs={6} md={6} lg={12}>
                                    <Button variant="info" className="py-0 my-1 shadow-sm" onClick={() => dialNumber(additionalInfo[networkKey].primary_isp_support)}>
                                        Call WAN1 Support
                                    </Button>
                                </Col>
                                <Col xs={6} md={6} lg={12}>
                                    <Button variant="info" className="py-0 my-1 shadow-sm" >
                                        Call WAN2 Support
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        {networkData[networkKey].cellularGateway && <Col xs={12} lg={4}>
                            <h5>Cellular Gateway</h5>
                            {renderDevices(networkData[networkKey].cellularGateway, 'cellularGateway')}
                        </Col>}
                        {networkData[networkKey].appliance && <Col xs={12} lg={4}>
                            <h5>Security Appliance</h5>
                            {renderDevices(networkData[networkKey].appliance, 'appliance')}
                        </Col>}
                        {networkData[networkKey].switch && <Col xs={12} lg={4}>
                            <h5>Network Switch</h5>
                            {renderDevices(networkData[networkKey].switch, 'switch')}
                        </Col>}
                        {networkData[networkKey].wireless && <Col xs={12} lg={4}>
                            <h5>Wireless Access</h5>
                            {renderDevices(networkData[networkKey].wireless, 'wireless')}
                        </Col>}
                    </Row>
                </React.Fragment>
            ))}
            </Container>
    </>
    );
}

function getColor(status) {
    return status === 'active' ? 'chartreuse' : status === 'ready' ? 'goldenrod' : 'red';
}

function getDeviceColor(status) {
    return status === 'online' ? 'green' : status === 'alerting' ? 'goldenrod' : status === 'offline' ? 'red' : 'grey';
}

function getIpTypeColor(status) {
    return status === 'dhcp' ? 'salmon' : status === 'static' ? 'red' : 'grey';
}

function findFallbackIp(network) {
    const deviceTypes = ['switch', 'wireless'];
    for (let type of deviceTypes) {
        if (network[type]) {
            for (let device of Object.values(network[type])) {
                if (device.publicIp) {
                    return device.publicIp;
                }
            }
        }
    }
    return 'N/A';
}

export default NetworkDetails;