import React from 'react';
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const RefreshButton = () => {
    const handleRefresh = () => {
        window.location.reload();
    };

    return (
        <Button variant="danger" className="text-light" size="sm" onClick={handleRefresh}>
            RESET
        </Button>
    );
};

export default RefreshButton;
