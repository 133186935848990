// InputFormComponent.js
import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import { Accordion, Card, Button, Modal, Container, Row, Col, Form, Alert, Dropdown } from 'react-bootstrap';
import Viewer from './Viewer';

const InputFormComponent = () => {
    const [properties, setProperties] = useState([]);
    const [buildings, setBuildings] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [filteredRooms, setFilteredRooms] = useState([]);
    // Separate form data for assets, projects, and notes to handle independent dropdown selections
    const [assetFormData, setAssetFormData] = useState({ parentRoomUUID: '', assetName: '', assetDesc: '' });
    const [projectFormData, setProjectFormData] = useState({ parentRoomUUID: '', projectName: '', projectDesc: '' });
    const [noteFormData, setNoteFormData] = useState({ parentRoomUUID: '', noteText: '' });
    const [propertyFormData, setPropertyFormData] = useState({ propertyName: '', acqDate: '', legalEnt: '', fund: '', city: '', state: '', zip: '' });
    const [buildingFormData, setBuildingFormData] = useState({ parentPropertyUUID: '', buildingName: '', buildingID: '', address: '', squareFt: '' });
    const [roomFormData, setRoomFormData] = useState({ parentBuildingUUID: '', roomName: '', number: '', use: '' });


    useEffect(() => {
        const db = firebase.database();
        db.ref('properties').on('value', snapshot => {
            const propertiesArray = snapshot.val() ? Object.entries(snapshot.val()).map(([key, value]) => ({ UUID: key, ...value })) : [];
            setProperties(propertiesArray);
        });

        db.ref('buildings').on('value', snapshot => {
            const buildingsArray = snapshot.val() ? Object.entries(snapshot.val()).map(([key, value]) => ({ UUID: key, ...value })) : [];
            setBuildings(buildingsArray);
        });

        db.ref('rooms').on('value', snapshot => {
            const roomsArray = snapshot.val() ? Object.entries(snapshot.val()).map(([key, value]) => ({ UUID: key, ...value })) : [];
            setRooms(roomsArray);
        });
    }, []);

    useEffect(() => {
        setFilteredRooms(rooms.filter(room => room.parentBuildingUUID === assetFormData.parentBuildingUUID || room.parentBuildingUUID === projectFormData.parentBuildingUUID || room.parentBuildingUUID === noteFormData.parentBuildingUUID));
    }, [rooms, assetFormData.parentBuildingUUID, projectFormData.parentBuildingUUID, noteFormData.parentBuildingUUID]);

    // Separate handleChange functions for each form data state to ensure independent management of state
    const handlePropertyChange = (e) => setPropertyFormData({ ...propertyFormData, [e.target.name]: e.target.value });
    const handleBuildingChange = (e) => setBuildingFormData({ ...buildingFormData, [e.target.name]: e.target.value });
    const handleRoomChange = (e) => setRoomFormData({ ...roomFormData, [e.target.name]: e.target.value });
    const handleAssetChange = (e) => setAssetFormData({ ...assetFormData, [e.target.name]: e.target.value });
    const handleProjectChange = (e) => setProjectFormData({ ...projectFormData, [e.target.name]: e.target.value });
    const handleNoteChange = (e) => setNoteFormData({ ...noteFormData, [e.target.name]: e.target.value });

    const handleInputChange = (e, formType) => {
        const { name, value } = e.target;
        switch (formType) {
            case 'asset':
                setAssetFormData(prev => ({ ...prev, [name]: value }));
                break;
            case 'project':
                setProjectFormData(prev => ({ ...prev, [name]: value }));
                break;
            case 'note':
                setNoteFormData(prev => ({ ...prev, [name]: value }));
                break;
            case 'room':
                setRoomFormData(prev => ({ ...prev, [name]: value }));
                break;
            default:
                break;
        }
    };

    const handleSubmit = (e, collection) => {
        e.preventDefault();
        const db = firebase.database();
        let submitData;

        switch (collection) {
            case 'properties':
                submitData = propertyFormData;
                break;
            case 'buildings':
                submitData = buildingFormData;
                break;
            case 'assets':
                submitData = assetFormData;
                setAssetFormData({ parentBuildingUUID: '', parentRoomUUID: '', assetName: '', assetDesc: '' }); // Reset form data
                break;
            case 'projects':
                submitData = projectFormData;
                setProjectFormData({ parentBuildingUUID: '', parentRoomUUID: '', projectName: '', projectDesc: '' }); // Reset form data
                break;
            case 'notes':
                submitData = noteFormData;
                setNoteFormData({ parentBuildingUUID: '', parentRoomUUID: '', noteText: '' }); // Reset form data
                break;
            case 'rooms':
                submitData = roomFormData;
                setRoomFormData({ parentBuildingUUID: '', roomName: '', number: '', use: '' }); // Reset form data
                break;
            default:
                return;
        }

        db.ref(collection).push(submitData);
        // Reset form data for the specific form submitted
        if (collection === 'properties') setPropertyFormData({ propertyName: '', acqDate: '', legalEnt: '', fund: '', city: '', state: '', zip: '' });
        if (collection === 'buildings') setBuildingFormData({ parentPropertyUUID: '', buildingName: '', buildingID: '', address: '', squareFt: '' });
        if (collection === 'rooms') setRoomFormData({ parentBuildingUUID: '', roomName: '', number: '', use: '' });
        if (collection === 'assets') setAssetFormData({ parentRoomUUID: '', assetName: '', assetDesc: '' });
        if (collection === 'projects') setProjectFormData({ parentRoomUUID: '', projectName: '', projectDesc: '' });
        if (collection === 'notes') setNoteFormData({ parentRoomUUID: '', noteText: '' });
    };

    // Function to sort and group options for dropdowns
    const getGroupedOptions = (items, parentItems) => {
        const groupedOptions = items.reduce((acc, item) => {
            const parentName = parentItems.find(parent => parent.UUID === item.parentUUID)?.propertyName || item.parentBuildingUUID && parentItems.find(parent => parent.UUID === item.parentBuildingUUID)?.buildingName;
            if (parentName) {
                if (!acc[parentName]) {
                    acc[parentName] = [];
                }
                acc[parentName].push(item);
            }
            return acc;
        }, {});

        return Object.entries(groupedOptions).sort((a, b) => a[0].localeCompare(b[0])).map(([groupName, items]) => ({
            groupName,
            items: items.sort((a, b) => a.name.localeCompare(b.name))
        }));
    };

    return (
        <>
            <Container fluid className="mb-2 px-0">
                <Row className="mb-2">
                    <Col xs={12} md={5}>
                        <Card>
                            <Card.Header className="bg-warning text-dark">Step 1: Create a Property</Card.Header>
                            <Card.Body>
                                <Form onSubmit={(e) => handleSubmit(e, 'properties')}>
                                    <Row>
                                        <Col md={5}>
                                            <Form.Group controlId="formPropertyName">
                                                <Form.Control type="text" placeholder="Enter property name" name="propertyName" value={propertyFormData.propertyName} onChange={handlePropertyChange} required />
                                                <Form.Label style={{ display: "none" }}>Property Name</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group controlId="formAcqDate">

                                                <Form.Control type="date" name="acqDate" value={propertyFormData.acqDate} onChange={handlePropertyChange} />
                                                <Form.Label style={{ display: "none" }}>Acquisition Date</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md="3">
                                            <Button variant="dark" type="submit" className="float-right px-2">Save Property</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                            {/* <Card.Footer>Set the Property Name &amp; Acquisition Date</Card.Footer> */}
                        </Card>
                    </Col>
                    <Col xs={12} md={7}>
                        <Card>
                            <Card.Header className="bg-warning text-dark">Step 2: Create a Building</Card.Header>
                            <Card.Body>
                                {properties.length > 0 && (
                                    <Form onSubmit={(e) => handleSubmit(e, 'buildings')}>
                                        <Row>
                                            <Col md={3}>
                                                <Form.Group controlId="formParentProperty">
                                                    <Form.Label style={{ display: "none" }}>Parent Property</Form.Label>
                                                    <Form.Control as="select" name="parentPropertyUUID" value={buildingFormData.parentPropertyUUID} onChange={handleBuildingChange} required>
                                                        <option value="" disabled>Select a Property</option>
                                                        {properties.map((property) => (
                                                            <option key={property.UUID} value={property.UUID}>{property.propertyName}</option>
                                                        ))}
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col md={3}>
                                                <Form.Group controlId="formBuildingName">
                                                    <Form.Label style={{ display: "none" }}>Building Name</Form.Label>
                                                    <Form.Control type="text" placeholder="Enter building name" name="buildingName" value={buildingFormData.buildingName} onChange={handleBuildingChange} required />
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group controlId="formBuildingName">
                                                    <Form.Label style={{ display: "none" }}>Building Name</Form.Label>
                                                    <Form.Control type="text" placeholder="Enter building address" name="address" value={buildingFormData.buildingAddress} onChange={handleBuildingChange} required />
                                                </Form.Group>
                                            </Col>
                                            <Col md={2}>
                                                <Button variant="dark" type="submit" className="float-right px-2">Save Building</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Card.Body>
                            {/* <Card.Footer>Add Buildings to each Property with an Address</Card.Footer> */}
                        </Card>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col xs={12} md={6}>
                        <Card>
                            <Card.Header className="bg-warning text-dark">Step 3: Create a Room</Card.Header>
                            <Card.Body>
                                {buildings.length > 0 && (
                                    <Form onSubmit={(e) => handleSubmit(e, 'rooms')}>
                                        <Row>
                                            <Col xs={12} md={3}>
                                                <Form.Group controlId="roomBuilding">
                                                    <Form.Control as="select" name="parentBuildingUUID" value={roomFormData.parentBuildingUUID} onChange={(e) => handleInputChange(e, 'room')}>
                                                        <option value="" disabled>Select a Building</option>
                                                        {buildings.map(building => <option key={building.UUID} value={building.UUID}>{building.buildingName}</option>)}
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={4}>
                                                <Form.Group controlId="roomName">
                                                    <Form.Control type="text" placeholder="Enter room name" name="roomName" value={roomFormData.roomName} onChange={(e) => handleInputChange(e, 'room')} />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <Form.Group controlId="roomNumber">
                                                    <Form.Control type="text" placeholder="Enter room number" name="number" value={roomFormData.number} onChange={(e) => handleInputChange(e, 'room')} />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={2}>
                                                <Button variant="dark" type="submit" className="float-right px-2">Save Room</Button>
                                            </Col>
                                        </Row>
                                        
                                    </Form>
                                )}
                            </Card.Body>
                            {/* <Card.Footer>Info text here</Card.Footer> */}
                        </Card>
                    </Col>
                    <Col xs={12} md={6}>
                        <Card>
                            <Card.Header className="bg-warning text-dark">Create an Note</Card.Header>
                            <Card.Body>
                                <Form onSubmit={(e) => handleSubmit(e, 'notes')}>
                                    <Row>
                                        <Col xs={12} md={3}>
                                            <Form.Group controlId="noteBuilding">
                                                <Form.Control as="select" name="parentBuildingUUID" value={noteFormData.parentBuildingUUID} onChange={(e) => handleInputChange(e, 'note')}>
                                                    <option value="" disabled>Select a Building</option>
                                                    {buildings.map(building => <option key={building.UUID} value={building.UUID}>{building.buildingName}</option>)}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <Form.Group controlId="noteRoom">
                                                <Form.Control as="select" name="parentRoomUUID" value={noteFormData.parentRoomUUID} onChange={(e) => handleInputChange(e, 'note')}>
                                                    <option value="" disabled>Select a Room</option>
                                                    {filteredRooms.map(room => <option key={room.UUID} value={room.UUID}>{room.roomName}</option>)}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group controlId="noteText">
                                                <Form.Label style={{ display: "none" }}>Note Name</Form.Label>
                                                <Form.Control type="text" placeholder="Enter note" name="noteText" value={noteFormData.buildingName} onChange={handleNoteChange} required />
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Button variant="dark" type="submit" className="float-right px-2">Save Note</Button>
                                        </Col>
                                    </Row>
                                    
                                </Form>
                            </Card.Body>
                            {/* <Card.Footer>Info text here</Card.Footer> */}
                        </Card>
                    </Col>
                </Row>
                <Row className="mb-2">
                    {rooms.length > 0 && (
                        <>
                            <Col xs={12} md={6}>
                                <Card>
                                    <Card.Header className="bg-warning text-dark">Create an Asset</Card.Header>
                                    <Card.Body>
                                        <Form onSubmit={(e) => handleSubmit(e, 'assets')}>
                                            <Row>
                                                <Col md={3}>
                                                    <Form.Group controlId="assetBuilding">
                                                        <Form.Control as="select" name="parentBuildingUUID" value={assetFormData.parentBuildingUUID} onChange={(e) => handleInputChange(e, 'asset')}>
                                                            <option value="" disabled>Select a Building</option>
                                                            {buildings.map(building => <option key={building.UUID} value={building.UUID}>{building.buildingName}</option>)}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Group controlId="assetRoom">
                                                        <Form.Control as="select" name="parentRoomUUID" value={assetFormData.parentRoomUUID} onChange={(e) => handleInputChange(e, 'asset')}>
                                                            <option value="" disabled>Select a Room</option>
                                                            {filteredRooms.map(room => <option key={room.UUID} value={room.UUID}>{room.roomName}</option>)}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Group controlId="assetName">
                                                        <Form.Label style={{ display: "none" }}>Asset Name</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter asset name" name="assetName" value={assetFormData.buildingName} onChange={handleAssetChange} required />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={2}>
                                                    <Button variant="dark" type="submit" className="float-right px-2">Save Asset</Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Card.Body>
                                    {/* <Card.Footer>Info text here</Card.Footer> */}
                                </Card>
                            </Col>
                            <Col xs={12} md={6}>
                                <Card>
                                    <Card.Header className="bg-warning text-dark">Create a Project</Card.Header>
                                    <Card.Body>
                                        <Form onSubmit={(e) => handleSubmit(e, 'projects')}>
                                            <Row>
                                                <Col xs={12} md={3}>
                                                    <Form.Group controlId="projectBuilding">
                                                        <Form.Control as="select" name="parentBuildingUUID" value={projectFormData.parentBuildingUUID} onChange={(e) => handleInputChange(e, 'project')}>
                                                            <option value="" disabled>Select a Building</option>
                                                            {buildings.map(building => <option key={building.UUID} value={building.UUID}>{building.buildingName}</option>)}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} md={3}>
                                                    <Form.Group controlId="projectRoom">
                                                        <Form.Control as="select" name="parentRoomUUID" value={projectFormData.parentRoomUUID} onChange={(e) => handleInputChange(e, 'project')}>
                                                            <option value="" disabled>Select a Room</option>
                                                            {filteredRooms.map(room => <option key={room.UUID} value={room.UUID}>{room.roomName}</option>)}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Group controlId="assetName">
                                                        <Form.Label style={{ display: "none" }}>Project Name</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter project name" name="projectName" value={projectFormData.buildingName} onChange={handleProjectChange} required />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={2}>
                                                    <Button variant="dark" type="submit" className="float-right px-1">Save Project</Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Card.Body>
                                    {/* <Card.Footer>Info text here</Card.Footer> */}
                                </Card>
                            </Col>
                        </>
                    )}
                </Row>
            </Container>
            {/* <Container fluid className="">
                <Viewer />
            </Container> */}
        </>
    );
};

export default InputFormComponent;