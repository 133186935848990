import React, { useState, useEffect } from 'react';

function MetricsComponent() {
    const [metrics, setMetrics] = useState('');

    useEffect(() => {
        const fetchMetrics = async () => {
            try {
                const response = await fetch('https://awake.surveyresults.zip/metrics', {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Basic ' + btoa(':uk1_8G_9d5LmWv8tznfcZHGo_amg9jPvc2yuLPf_Heby')
                    }
                });
                const data = await response.text();
                setMetrics(data);
            } catch (error) {
                console.error('Failed to fetch metrics:', error);
            }
        };

        fetchMetrics();
    }, []);

    return (
        <div>
            <h1>Metrics Information</h1>
            <pre>{metrics}</pre>
        </div>
    );
}

export default MetricsComponent;
