import React, { useState } from 'react';
import { CSVLink } from "react-csv";
import Papa from 'papaparse';

const DataManagementComponent = ({ jsonData }) => {
    const [csvData, setCsvData] = useState('');

    const exportToJson = (data) => {
        let header = ['UUID', 'Key', 'Value'];
        let rows = [];

        const iterate = (obj, parent = '') => {
            Object.keys(obj).forEach(key => {
                if (typeof obj[key] === 'object' && obj[key] !== null) {
                    iterate(obj[key], `${parent}${key}/`);
                } else {
                    rows.push([parent.slice(0, -1), key, obj[key]]);
                }
            });
        };

        iterate(data);
        setCsvData(Papa.unparse({ fields: header, data: rows }));
    };

    const importFromCsv = (csv) => {
        Papa.parse(csv, {
            header: true,
            complete: (results) => {
                let jsonData = {};
                results.data.forEach(({ UUID, Key, Value }) => {
                    const path = UUID.split('/');
                    let current = jsonData;

                    for (let i = 0; i < path.length - 1; i++) {
                        current[path[i]] = current[path[i]] || {};
                        current = current[path[i]];
                    }

                    current[path[path.length - 1]] = Value;
                });

                console.log(jsonData);
            }
        });
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const text = e.target.result;
                importFromCsv(text);
            };
            reader.readAsText(file);
        }
    };

    return (
        <div>
            <button onClick={() => exportToJson(jsonData)}>Export to CSV</button>
            {csvData && <CSVLink data={csvData} filename={"export.csv"}>Download CSV</CSVLink>}
            <input type="file" accept=".csv" onChange={handleFileUpload} />
        </div>
    );
};

export default DataManagementComponent;
