import React, { useState, useEffect } from 'react';
import { Container, Row, Col, ListGroup, Card } from 'react-bootstrap';
import firebase from 'firebase/app';
import 'firebase/database';

const DisplayComponent = () => {
    const [buildings, setBuildings] = useState([]);
    const [properties, setProperties] = useState([]);
    const [selected, setSelected] = useState({});
    const [infoData, setInfoData] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            const db = firebase.database();
            const buildingsSnapshot = await db.ref('buildings').once('value');
            const propertiesSnapshot = await db.ref('properties').once('value');
            setBuildings(buildingsSnapshot.val() || []);
            setProperties(propertiesSnapshot.val() || []);
        };
        fetchData();
    }, []);

    const selectItem = (item, type) => {
        setSelected({ type, item });
        // Logic to fetch more info based on selected item
        setInfoData({}); // Reset info data
    };

    return (
        <Container fluid>
            <Row>
                <Col md={3}>
                    <ListGroup>
                        {Object.entries(buildings).map(([id, building]) => (
                            <ListGroup.Item key={id} action onClick={() => selectItem(building, 'building')}>
                                {building.name}
                                <ListGroup variant="flush">
                                    {Object.entries(properties).filter(([_, p]) => p.buildingId === id).map(([propId, property]) => (
                                        <ListGroup.Item key={propId} action onClick={() => selectItem(property, 'property')}>
                                            {'-- ' + property.name}
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Col>
                <Col md={3}>
                    <ListGroup>
                        {selected.type === 'building' && <ListGroup.Item action>Projects</ListGroup.Item>}
                        {selected.type === 'property' && <ListGroup.Item action>Assets</ListGroup.Item>}
                        <ListGroup.Item action>Notes</ListGroup.Item>
                    </ListGroup>
                </Col>
                <Col md={6}>
                    <Card>
                        <Card.Body>
                            {infoData && Object.keys(infoData).length > 0
                                ? Object.entries(infoData).map(([key, value]) => (
                                    <p key={key}>
                                        {key}: {value}
                                    </p>
                                ))
                                : <p>Select an item to view details.</p>}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default DisplayComponent;
