import React from 'react';
import { Container, Card, Button, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/auth';

import app from '../firebase';
import { useFlashUpdate } from '../contexts/FlashContext';

export default function MicrosoftLogin() {
    const flash = useFlashUpdate();
    const history = useHistory();

    async function handleLogin(event) {
        event.preventDefault();

        try {
            var provider = new firebase.auth.OAuthProvider('microsoft.com');
            provider.setCustomParameters({
                tenant: 'd98af180-3858-42b0-bfa2-8e2369646b1a' // Replace with your specific tenant ID
            });
            provider.addScope('User.Read'); // Add any additional scopes here

            const result = await app.auth().signInWithPopup(provider);

            var user = result.user;
            var providerId = result.additionalUserInfo.providerId;
            var profile = result.additionalUserInfo.profile;
            var token = result.credential.accessToken;

            console.log('USER:', user); // user.uid, user.displayName, user.email, user.emailVerified, user.phoneNumber, user.photoURL, user.refreshToken
            console.log('PROVIDER:', providerId);
            console.log('USER PROFILE:', profile);
            console.log('ACCESS TOKEN', token);
            // TODO: store this user info in the database, perhaps firestore

            console.log('LOGIN SUCCESS');
            flash({ message: 'Login success. Welcome back!', variant: 'success' });
            history.push('/profile');
        } catch (error) {
            console.error('Error during login:', error);
            flash({ message: `Login failed: ${error.message}`, variant: 'danger' });
        }
    }

    return (
        <Container className="d-flex align-items-center justify-content-center">
            <div className="w-100" style={{ maxWidth: '400px', marginTop: '50px' }}>
                <Card>
                    <Card.Body>
                        <h2 className="text-center mb-4">Prefer to use Microsoft?</h2>

                        <Form onSubmit={handleLogin}>
                            <Button className="w-100 shadow-sm" type="submit" variant="warning">
                                Log In w/ Microsoft
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </div>
        </Container>
    );
}
