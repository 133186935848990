// Updated DisplayComponent.js to reflect the changes in data structure
import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import { Accordion, Card, Button, Modal, Container, Row, Col, Form, Alert } from 'react-bootstrap';
import { useAuth } from "../contexts/FirebaseAuth"; // Make sure the path is correct

const DisplayComponent = () => {
    const [properties, setProperties] = useState([]);
    const [buildings, setBuildings] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [assets, setAssets] = useState([]);
    const [projects, setProjects] = useState([]);
    const [notes, setNotes] = useState([]);
    const [showRoomModal, setShowRoomModal] = useState(false);
    const [showItemModal, setShowItemModal] = useState(false);
    const [selectedRoomUUID, setSelectedRoomUUID] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);
    const [itemType, setItemType] = useState('');
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [showMoreInfoModal, setShowMoreInfoModal] = useState(false);
    const [moreInfoData, setMoreInfoData] = useState({});
    const [breadcrumb, setBreadcrumb] = useState({});
    const [activeKey, setActiveKey] = useState('0'); // State to control the active key of the Accordion
    const { currentUser } = useAuth(); // Use the useAuth hook to get the current user

    useEffect(() => {
        const db = firebase.database();
        db.ref('properties').on('value', snapshot => {
            const propertiesArray = [];
            snapshot.forEach(childSnapshot => {
                propertiesArray.push({ UUID: childSnapshot.key, ...childSnapshot.val() });
            });
            setProperties(propertiesArray);
        });

        db.ref('buildings').on('value', snapshot => {
            const buildingsArray = [];
            snapshot.forEach(childSnapshot => {
                buildingsArray.push({ UUID: childSnapshot.key, ...childSnapshot.val() });
            });
            setBuildings(buildingsArray);
        });

        db.ref('rooms').on('value', snapshot => {
            const roomsArray = [];
            snapshot.forEach(childSnapshot => {
                roomsArray.push({ UUID: childSnapshot.key, ...childSnapshot.val() });
            });
            setRooms(roomsArray);
        });

        db.ref('assets').on('value', snapshot => {
            const assetsArray = [];
            snapshot.forEach(childSnapshot => {
                assetsArray.push({ UUID: childSnapshot.key, ...childSnapshot.val() });
            });
            setAssets(assetsArray);
        });

        db.ref('projects').on('value', snapshot => {
            const projectsArray = [];
            snapshot.forEach(childSnapshot => {
                projectsArray.push({ UUID: childSnapshot.key, ...childSnapshot.val() });
            });
            setProjects(projectsArray);
        });

        db.ref('notes').on('value', snapshot => {
            const notesArray = [];
            snapshot.forEach(childSnapshot => {
                notesArray.push({ UUID: childSnapshot.key, ...childSnapshot.val() });
            });
            setNotes(notesArray);
        });

        db.ref('comments').orderByChild('timestamp').on('value', snapshot => {
            const commentsArray = [];
            snapshot.forEach(childSnapshot => {
                const commentDate = new Date(childSnapshot.val().timestamp);
                const dateString = `${childSnapshot.val().email} at ${commentDate.toLocaleString()}`;
                commentsArray.unshift({ id: childSnapshot.key, text: childSnapshot.val().text, dateString });
            });
            setComments(commentsArray);
        });
    }, []);

    const getBuildingsByPropertyUUID = (propertyUUID) => buildings.filter(building => building.parentPropertyUUID === propertyUUID);

    const getRoomsByBuildingUUID = (buildingUUID) => rooms.filter(room => room.parentBuildingUUID === buildingUUID);

    const getItemsByRoomUUID = (items, roomUUID) => items.filter(item => item.parentRoomUUID === roomUUID);

    const handleRoomClick = (roomUUID, buildingName) => {
        setSelectedRoomUUID(roomUUID);
        setBreadcrumb(`${buildingName} > ${rooms.find(room => room.UUID === roomUUID)?.roomName}`);
        setShowRoomModal(true);
    };

    const handleItemClick = (item, type) => {
        setSelectedItem(item);
        setItemType(type);
        setShowItemModal(true);
    };

    const saveChanges = () => {
        if (itemType && selectedItem) {
            firebase.database().ref(`${itemType}/${selectedItem.UUID}`).set(selectedItem);
        }
        setShowItemModal(false);
    };

    const handleChange = (e) => {
        setSelectedItem({ ...selectedItem, [e.target.name]: e.target.value });
    };

    const addComment = (parentUUID) => {
        if (newComment.trim() !== '') {
            const email = currentUser ? currentUser.email : 'Anonymous'; // Use the email if currentUser exists, otherwise 'Anonymous'
            firebase.database().ref('comments').push({
                text: newComment,
                parentUUID: parentUUID,
                timestamp: firebase.database.ServerValue.TIMESTAMP,
                email: email // Include the email of the user who posted the comment
            });
            setNewComment('');
        }
    };

    const deleteComment = (commentId) => {
        if (window.confirm('Are you sure you want to delete this comment?')) {
            firebase.database().ref(`comments/${commentId}`).remove();
        }
    };

    const addCommentToItem = (parentUUID) => {
        if (newComment.trim() !== '') {
            firebase.database().ref('comments').push({
                text: newComment,
                parentUUID: parentUUID,
                timestamp: firebase.database.ServerValue.TIMESTAMP
            });
            setNewComment('');
        }
    };

    const handleMoreInfoClick = (data, type) => {
        setMoreInfoData({ ...data, type });
        setShowMoreInfoModal(true);
    };

    const updateMoreInfoData = (e) => {
        setMoreInfoData({ ...moreInfoData, [e.target.name]: e.target.value });
    };

    const saveMoreInfoChanges = () => {
        if (moreInfoData.type && moreInfoData.UUID) {
            firebase.database().ref(`${moreInfoData.type}/${moreInfoData.UUID}`).set(moreInfoData);
            setShowMoreInfoModal(false);
        }
    };

    return (
        <>
            <Accordion activeKey={activeKey} onSelect={(eventKey) => setActiveKey(eventKey)}>
                {properties.map((property, index) => (
                    <Card key={property.UUID}>
                        <Card.Header className="d-flex justify-content-between align-items-center bg-dark text-light" onClick={() => setActiveKey(index.toString())}>
                            {property.propertyName}
                            <Button variant="warning" size="sm" onClick={() => handleMoreInfoClick(property, 'properties')}>
                                More Info
                            </Button>
                        </Card.Header>
                        <Accordion.Collapse eventKey={String(index)}>
                            <Card.Body>
                                <Row>
                                    {getBuildingsByPropertyUUID(property.UUID).map((building, buildingIndex) => (
                                        <Col xs={12} md={4} key={building.UUID}>
                                            <Card className="mb-2">
                                                <Card.Header className="d-flex justify-content-between align-items-center bg-dark text-light">
                                                    {building.buildingName}
                                                    <Button variant="warning" size="sm" onClick={() => handleMoreInfoClick(building, 'buildings')}>
                                                        More Info
                                                    </Button>
                                                </Card.Header>
                                                <Card.Body>
                                                    {getRoomsByBuildingUUID(building.UUID).map(room => (
                                                        <Button key={room.UUID} variant="light" className="m-1" onClick={() => handleRoomClick(room.UUID, building.buildingName)}>
                                                            {room.roomName}
                                                        </Button>
                                                    ))}
                                                </Card.Body>
                                                <Card.Footer>
                                                    <small className="text-muted">
                                                        Address: {building.address} | Square Feet: {building.squareFt}
                                                    </small>
                                                </Card.Footer>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                ))}
            </Accordion>

            <Modal show={showRoomModal} onHide={() => setShowRoomModal(false)} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">{breadcrumb}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row className="show-grid">
                            <Col xs={12} md={4}>
                                <h5>Projects</h5>
                                {getItemsByRoomUUID(projects, selectedRoomUUID).map(project => (
                                    // Convert each Project name into a clickable hyperlink that opens the item modal for editing
                                    <Button key={project.UUID} variant="link" onClick={() => handleItemClick(project, 'projects')}>
                                        {project.projectName}
                                    </Button>
                                ))}
                            </Col>
                            <Col xs={12} md={4}>
                                <h5>Assets</h5>
                                {getItemsByRoomUUID(assets, selectedRoomUUID).map(asset => (
                                    // Convert each Asset name into a clickable hyperlink that opens the item modal for editing
                                    <Button key={asset.UUID} variant="link" onClick={() => handleItemClick(asset, 'assets')}>
                                        {asset.assetName}
                                    </Button>
                                ))}
                            </Col>
                            <Col xs={12} md={4}>
                                <h5>Notes</h5>
                                {getItemsByRoomUUID(notes, selectedRoomUUID).map(note => (
                                    // Convert each Note text into a clickable hyperlink that opens the item modal for editing
                                    <Button key={note.UUID} variant="link" onClick={() => handleItemClick(note, 'notes')}>
                                        {note.noteText}
                                    </Button>
                                ))}
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xs={12}>
                                <h5>Comments</h5>
                                <Form className="my-3">
                                    <Form.Group controlId="newComment">
                                        {/* <Form.Label>Add a comment</Form.Label> */}
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            value={newComment}
                                            onChange={(e) => setNewComment(e.target.value)}
                                        />
                                    </Form.Group>
                                    <Button onClick={() => addComment(selectedRoomUUID)} variant="warning">Submit Comment</Button>
                                </Form>
                                {comments.map((comment) => (
                                    <Alert key={comment.id} variant="secondary" className="mt-2">
                                        {comment.text} - <i>{comment.dateString}</i> {/* Use the formatted dateString */}
                                        <Button variant="outline-danger" size="sm" className="float-right" onClick={() => deleteComment(comment.id)}>x</Button>
                                    </Alert>
                                ))}
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowRoomModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>

            {/* Item Details/Edit Modal */}
            <Modal show={showItemModal} onHide={() => setShowItemModal(false)} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Edit {itemType.charAt(0).toUpperCase() + itemType.slice(1)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {Object.keys(selectedItem || {}).map((key) => (
                            !key.endsWith('UUID') && <Form.Group key={key}>
                                <Form.Label>{key.charAt(0).toUpperCase() + key.slice(1)}</Form.Label>
                                <Form.Control type="text" name={key} value={selectedItem[key]} onChange={handleChange} />
                            </Form.Group>
                        ))}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowItemModal(false)}>Cancel</Button>
                    <Button variant="primary" onClick={saveChanges}>Save Changes</Button>
                </Modal.Footer>
            </Modal>

            {/* "More Info" Modal */}
            <Modal show={showMoreInfoModal} onHide={() => setShowMoreInfoModal(false)} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>More Info - {moreInfoData.name || ''}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Form>
                            {Object.keys(moreInfoData).map(key => (
                                key !== 'UUID' && key !== 'type' && // Exclude UUID and type from being editable
                                <Form.Group key={key}>
                                    <Form.Label>{key.charAt(0).toUpperCase() + key.slice(1)}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={key}
                                        value={moreInfoData[key]}
                                        onChange={updateMoreInfoData}
                                    />
                                </Form.Group>
                            ))}
                        </Form>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowMoreInfoModal(false)}>Cancel</Button>
                    <Button variant="primary" onClick={saveMoreInfoChanges}>Save Changes</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default DisplayComponent;