import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import { Table, Button, Modal } from 'react-bootstrap';

const DisplayComponent = () => {
    const [data, setData] = useState({ properties: [], buildings: [], rooms: [], assets: [], projects: [], notes: [], comments: [] });
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [itemToDelete, setItemToDelete] = useState({ type: '', id: '', childCount: 0 });
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [detailItem, setDetailItem] = useState({});

    useEffect(() => {
        const db = firebase.database();
        const types = ['properties', 'buildings', 'rooms', 'assets', 'projects', 'notes', 'comments'];
        types.forEach(type => {
            db.ref(type).on('value', snapshot => {
                const itemsArray = [];
                snapshot.forEach(childSnapshot => {
                    itemsArray.push({ id: childSnapshot.key, ...childSnapshot.val() });
                });
                setData(prevData => ({ ...prevData, [type]: itemsArray }));
            });
        });
    }, []);

    const getChildCount = (type, id) => {
        let count = 0;
        if (type === 'properties') {
            count += data.buildings.filter(building => building.parentPropertyUUID === id).length;
        }
        if (type === 'properties' || type === 'buildings') {
            count += data.rooms.filter(room => room.parentBuildingUUID === id).length;
        }
        if (type === 'properties' || type === 'buildings' || type === 'rooms') {
            count += data.assets.filter(asset => asset.parentRoomUUID === id).length;
            count += data.projects.filter(project => project.parentRoomUUID === id).length;
            count += data.notes.filter(note => note.parentRoomUUID === id).length;
            count += data.comments.filter(comment => comment.parentUUID === id).length;
        }
        return count;
    };

    const openDeleteConfirmation = (type, id) => {
        const childCount = getChildCount(type, id);
        setItemToDelete({ type, id, childCount });
        setShowDeleteConfirmation(true);
    };

    const handleDelete = () => {
        const { type, id } = itemToDelete;
        setShowDeleteConfirmation(false);
        if (itemToDelete.childCount === 0) {
            firebase.database().ref(`${type}/${id}`).remove();
        }
    };

    const viewDetails = (item) => {
        setDetailItem(item);
        setShowDetailModal(true);
    };

    const renderTable = (type) => (
        <div key={type}>
            <h3>{type.charAt(0).toUpperCase() + type.slice(1)}</h3>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>UUID</th>
                        <th>Name</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {data[type].map((item) => (
                        <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item[`${type.slice(0, -1)}Name`] || item.noteText || item.assetName || item.projectName || item.roomName || item.buildingName || item.propertyName || item.text}</td>
                            <td>
                                <Button variant="info" size="sm" onClick={() => viewDetails(item)} className="mr-2">
                                    View
                                </Button>
                                <Button variant="danger" size="sm" onClick={() => openDeleteConfirmation(type, item.id)}>
                                    x
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );

    const tables = Object.keys(data).map(type => renderTable(type));

    return (
        <>
            {tables}
            <Modal show={showDeleteConfirmation} onHide={() => setShowDeleteConfirmation(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {itemToDelete.childCount > 0
                        ? `This item has ${itemToDelete.childCount} child associations and cannot be deleted.`
                        : 'Are you sure you want to delete this item? This action cannot be undone.'}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteConfirmation(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDelete} disabled={itemToDelete.childCount > 0}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showDetailModal} onHide={() => setShowDetailModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Item Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {Object.entries(detailItem).map(([key, value]) => (
                        <p key={key}>
                            <strong>{key}:</strong> {value}
                        </p>
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDetailModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default DisplayComponent;
