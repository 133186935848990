import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import { Container, Row, Col, Card, ProgressBar } from 'react-bootstrap';

const firebaseConfig = {
    // Add your Firebase configuration here
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
} else {
    firebase.app();
}

const PrinterComponent = () => {
    const [printers, setPrinters] = useState([]);
    const [monitorData, setMonitorData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const printersRef = firebase.database().ref('printers');
        printersRef.on('value', (snapshot) => {
            const data = snapshot.val();
            if (data) {
                setPrinters(Object.values(data));
            } else {
                setPrinters([]);
            }
            setLoading(false);
        });

        const monitorRef = firebase.database().ref('/kuma-devices/monitor_response_time');
        monitorRef.on('value', (snapshot) => {
            const data = snapshot.val();
            if (data) {
                setMonitorData(Object.values(data));
            }
        });

        return () => {
            printersRef.off();
            monitorRef.off();
        };
    }, []);

    const getTonerColor = (toner) => {
        switch (toner) {
            case 'Black':
                return '#000000';
            case 'Cyan':
                return '#00FFFF';
            case 'Magenta':
                return '#FF00FF';
            case 'Yellow':
                return '#FFFF00';
            default:
                return 'grey';
        }
    };

    const getTextColor = (backgroundColor) => {
        const r = parseInt(backgroundColor.substr(1, 2), 16);
        const g = parseInt(backgroundColor.substr(3, 2), 16);
        const b = parseInt(backgroundColor.substr(5, 2), 16);
        return (r * 0.299 + g * 0.587 + b * 0.114) > 186 ? '#000000' : '#FFFFFF';
    };

    const getMonitorDetails = (ip) => {
        return monitorData.find((monitor) => monitor.monitor_hostname === ip) || {};
    };

    return (
        <Container fluid>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <>

                    <Row>
                        <Col xs={12} md={12} lg={4} className="h4 text-left">
                            Printers | Managed & Monitored
                        </Col>

                        <Col xs={12} md={12} lg={4} className="h4 text-center">
                            &nbsp;
                        </Col>

                        <Col xs={12} md={12} lg={4} className="h6 text-right text-uppercase text-info">
                            <p>Currently monitoring {printers.length} printers</p>
                        </Col>
                    </Row>
                    <Row>
                        {printers.map((printer, index) => {
                            const monitorDetails = getMonitorDetails(printer.ip);
                            const displayName = printer.name === 'Unknown' ? monitorDetails.monitor_name : printer.name;

                            return (
                                <Col md={4} key={index}>
                                    <Card className="mb-3">
                                        <Card.Header>
                                            <strong>Name: <span className="text-white text-bold">{displayName}</span></strong><br />
                                            <Row>
                                                <Col md={4}>IP: <a href={`http://${printer.ip}`} target="_blank" rel="noopener noreferrer" style={{ color: '#FFFF00' }}>{printer.ip}</a></Col>
                                                <Col md={8} className="text-right"> MAC: <span className="text-white text-uppercase">{printer.mac}</span></Col>
                                            </Row>
                                        </Card.Header>
                                        <Card.Body>
                                            <Row>
                                                <Col>
                                                    <h5>Paper Level</h5>
                                                    {Array.isArray(printer.paper) && printer.paper.map((level, idx) => (
                                                        level !== null && (
                                                            <ProgressBar
                                                                key={idx}
                                                                now={level / 5}
                                                                label={`${level}`}
                                                                max={100}
                                                                variant="white"
                                                                className="mb-2"
                                                                style={{ color: 'black' }}
                                                            />
                                                        )
                                                    ))}
                                                </Col>
                                                <Col>
                                                    <h5>Toner Level</h5>
                                                    {printer.toner && Object.entries(printer.toner).map(([color, level]) => {
                                                        const bgColor = getTonerColor(color);
                                                        const textColor = getTextColor(bgColor);
                                                        return (
                                                            <ProgressBar
                                                                key={color}
                                                                now={level}
                                                                label={`${level}%`}
                                                                max={100}
                                                                className="mb-2 rounded text-bold"
                                                                style={{ backgroundColor: bgColor, color: bgColor, textAlign: 'center' }}
                                                            />
                                                        );
                                                    })}
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                        <Card.Footer>
                                            <Row style={{ fontSize: "0.85rem" }}>
                                                <Col md={7}>Uptime: <span className="text-white text-uppercase">{printer.uptime}</span> <br /></Col>
                                                <Col md={5} className="text-right">Response Time: <span className="text-white text-uppercase">{monitorDetails.value}</span>ms</Col>
                                            </Row>
                                        </Card.Footer>
                                    </Card>
                                </Col>
                            );
                        })}
                    </Row>
                </>
            )}
        </Container>
    );
};

export default PrinterComponent;
